import { TrainerDto, TrainerItemDto, TrainerListDto } from '../api/data/types'
import { TrainerFormData } from '../types/forms/data'
import { TrainerItem, Trainer, TrainerList } from '../types/trainer'

export function mapTrainerListDto(trainers: TrainerListDto): TrainerList {
  return {
    totalCount: trainers.totalCount,
    trainers: trainers.trainers.map(mapTrainerItemDto)
  }
}

export function mapTrainerItemDto(trainer: TrainerItemDto): TrainerItem {
  return {
    ...trainer
  }
}

export function mapTrainerDto(trainer: TrainerDto): Trainer {
  return {
    ...trainer
  }
}

export function mapTrainer(trainer: Trainer): TrainerFormData {
  return {
    firstName: trainer.firstName,
    lastName: trainer.lastName,
    nick: trainer.nick ?? '',
    email: trainer.contact.email,
    phone: trainer.contact.phone ?? ''
  }
}