import { Grid, Pagination } from '@mui/material'
import { ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DecodedValueMap, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import EmptyListPlaceholder from '../../common/EmptyListPlaceholder'
import ListConnectionError from '../../common/ListConnectionError'
import Loader from '../../common/Loader'
import { useQueryClient } from '@tanstack/react-query'
import { queryNames } from '../../../hooks/queries'
import { useParams } from 'react-router-dom'
import useGetPlaceTrainingRoomList from '../../../hooks/data/rooms/useGetPlaceTrainingRoomList'
import Tile from './Tile'
import { RoomListSearchParams } from '../../../types/rooms'
import { contentContainerId } from '../../../utils/const'

const roomsPerPage = Number.parseInt(process.env.REACT_APP_ROOMS_PER_PAGE!)

function isSearchParamsValid(params: DecodedValueMap<RoomListSearchParams>): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
}

function Container(props: { children: ReactNode | ReactNode[] }) {
  return (
    <Grid 
      container 
      flexDirection='column'
      width='100%'
      marginY='1.5rem'
      gap='1.5rem'
    >
      {props.children}
    </Grid>
  )
}

export default function MainSection() {
  const texts = useTranslation('roomList').t
  const queryClient = useQueryClient()

  const { uuid } = useParams<{ uuid?: string }>()

  const [searchParams, setSearchParams] = useQueryParams<RoomListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    search: withDefault(StringParam, undefined)
  })

  const roomList = useGetPlaceTrainingRoomList(uuid ?? '', {
    search: searchParams.search ? decodeURI(searchParams.search) : undefined,
    limit: roomsPerPage,
    offset: (searchParams.page - 1) * roomsPerPage,
    enabled: uuid != null && uuid !== '' && isSearchParamsValid(searchParams),
    onSuccess: (data) => {
      if (searchParams.page > 1 && data.trainingRooms.length === 0) {
        setSearchParams({
          page: Math.ceil((data.totalCount ?? 0) / roomsPerPage)
        }, 'replaceIn')
      }
    }
  }, )

  const maxPage = useMemo(() => {
    return Math.ceil((roomList.data?.totalCount ?? 0) / roomsPerPage)
  }, [roomList.data?.totalCount])

  useEffect(() => {
    document.getElementById(contentContainerId)?.scrollTo(0, 0)
  }, [searchParams.page])

  useEffect(() => {
    if (!isSearchParamsValid(searchParams)) {
      setSearchParams({
        page: 1, 
        search: ''
      }, 'replaceIn')
      return
    }
    
    if (searchParams.page === 1) {
      setSearchParams({
        page: 1
      }, 'replaceIn')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onPageChange = (_: React.ChangeEvent<any>, value: number) => {
    setSearchParams({ page: value, search: searchParams.search }, 'push')
  }

  if (roomList.isFetching) {
    return (
      <Container>
        <Grid marginTop='10rem' width='100%'>
          <Loader />
        </Grid>
      </Container>
    )
  }

  const isPageNotFound = searchParams.page > 1 && roomList.data!.trainingRooms.length === 0
  if (!isSearchParamsValid(searchParams) || isPageNotFound) {
    return <></>
  }
  
  if (roomList.isError) {
    return (
      <Container>
        <Grid marginTop='10rem' width='100%'>
          <ListConnectionError 
            onReload={() => {
              queryClient.resetQueries([
                queryNames.getPlaceList,
                searchParams.search,
                roomsPerPage,
                (searchParams.page - 1) * roomsPerPage
              ])
            }}
          />
        </Grid>
      </Container>
    )
  }

  if (roomList.data!.trainingRooms.length === 0) {
    return (
      <Container>
        <Grid marginTop='10rem' width='100%'>
          <EmptyListPlaceholder
            title={texts('no_data_title')}
            description={texts('no_data_description')}
          />
        </Grid>
      </Container>
    )
  }

  return (
    <Container>
      <Grid
        item
        container
        width='100%'
        flexDirection='column'
        gap='.75rem'
      >
        {
          roomList.data!.trainingRooms.map(room => 
            <Tile 
              key={room.uuid} 
              locationUuid={uuid!}
              room={room} 
            />
          )
        }
      </Grid>
      <Pagination 
        count={maxPage}
        page={searchParams.page}
        color='secondary'
        onChange={onPageChange}
        sx={{
          alignSelf: 'center'
        }}
      />
    </Container>
  )
}