import { Box, Typography } from '@mui/material'

type Props = {
  color: string
  label: string
}

export default function TableBadge(props: Props) {
  return (
    <Box
      sx={{
        backgroundColor: props.color,
        width: '100%',
        textAlign: 'center',
        borderRadius: '.75rem',
        overflow: 'hidden',
        paddingBlock: '.125rem'
      }}
    >
      <Typography variant='badge'>
        {props.label}
      </Typography>
    </Box>
  )
}

