import { Grid, Pagination } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DecodedValueMap, NumberParam, useQueryParams, withDefault } from 'use-query-params'
import useGetExceptionList from '../../../hooks/data/exceptions/useGetExceptionList'
import { queryNames } from '../../../hooks/queries'
import { BaseListSearchParams } from '../../../types/common'
import EmptyListPlaceholder from '../../common/EmptyListPlaceholder'
import ListConnectionError from '../../common/ListConnectionError'
import Loader from '../../common/Loader'
import Tile from './Tile'
import { contentContainerId } from '../../../utils/const'

const exceptionsPerPage = Number.parseInt(process.env.REACT_APP_EXCEPTIONS_PER_PAGE!)

function isSearchParamsValid(params: DecodedValueMap<BaseListSearchParams>): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
}

function Container(props: { children: ReactNode | ReactNode[] }) {
  return (
    <Grid 
      container 
      flexDirection='column'
      width='100%'
      marginY='1.5rem'
      gap='1.5rem'
    >
      {props.children}
    </Grid>
  )
}

export default function MainSection() {
  const texts = useTranslation('exceptionList').t
  const queryClient = useQueryClient()

  const [searchParams, setSearchParams] = useQueryParams<BaseListSearchParams>({ 
    page: withDefault(NumberParam, 1)
  })

  const exceptionList = useGetExceptionList({
    limit: exceptionsPerPage,
    offset: (searchParams.page - 1) * exceptionsPerPage,
    timezone: process.env.REACT_APP_DEFAULT_TZ!,
    enabled: isSearchParamsValid(searchParams),
    onSuccess: (data) => {
      if (searchParams.page > 1 && data.exceptions.length === 0) {
        setSearchParams({
          page: Math.ceil((data.totalCount ?? 0) / exceptionsPerPage),
        }, 'replaceIn')
      }
    }
  })

  const maxPage = useMemo(() => {
    return Math.ceil((exceptionList.data?.totalCount ?? 0) / exceptionsPerPage)
  }, [exceptionList.data?.totalCount])

  useEffect(() => {
    document.getElementById(contentContainerId)?.scrollTo(0, 0)
  }, [searchParams.page])

  useEffect(() => {
    if (!isSearchParamsValid(searchParams)) {
      setSearchParams({
        page: 1
      }, 'replaceIn')
      return
    }
    
    if (searchParams.page === 1) {
      setSearchParams({
        page: 1
      }, 'replaceIn')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPageChange = (_: React.ChangeEvent<any>, value: number) => {
    setSearchParams({ page: value }, 'push')
  }

  if (exceptionList.isFetching) {
    return (
      <Container>
        <Grid marginTop='10rem' width='100%'>
          <Loader />
        </Grid>
      </Container>
    )
  }

  const isPageNotFound = searchParams.page > 1 && exceptionList.data!.exceptions.length === 0
  if (!isSearchParamsValid(searchParams) || isPageNotFound) {
    return <></>
  }
  
  if (exceptionList.isError) {
    return (
      <Container>
        <Grid marginTop='10rem' width='100%'>
          <ListConnectionError 
            onReload={() => {
              queryClient.resetQueries([
                queryNames.getExceptionList,
                exceptionsPerPage,
                (searchParams.page - 1) * exceptionsPerPage
              ])
            }}
          />
        </Grid>
      </Container>
    )
  }

  if (exceptionList.data!.exceptions.length === 0) {
    return (
      <Container>
        <Grid marginTop='10rem' width='100%'>
          <EmptyListPlaceholder
            title={texts('no_data_title')}
            description={texts('no_data_description')}
          />
        </Grid>
      </Container>
    )
  }

  return (
    <Container>
      <Grid
        item
        container
        width='100%'
        flexDirection='column'
        gap='.75rem'
      >
        {
          exceptionList.data!.exceptions.map(exception => 
            <Tile 
              key={exception.uuid} 
              exception={exception} 
            />
          )
        }
      </Grid>
      {
        maxPage > 1
        && <Pagination 
            count={maxPage}
            page={searchParams.page}
            color='secondary'
            onChange={onPageChange}
            sx={{
              alignSelf: 'center'
            }}
          />
      }
    </Container>
  )
}