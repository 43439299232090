import { KeyboardBackspace } from '@mui/icons-material'
import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function TopSection() {
  const texts = useTranslation('exceptionList').t
  const navigate = useNavigate()

  return (
    <Grid marginY='2rem'>
      <Button 
        variant='text'
        onClick={() => { navigate('/schedule', { replace: true })}}
        sx={{ marginBottom: '1rem' }}
      >
        <Grid
          container
          alignItems='center'
          gap='.5rem'
        >
          <KeyboardBackspace sx={{ marginTop: '-.125rem' }} />
          {texts('back_to_list')}
        </Grid>
      </Button>
      <Grid
        container
        justifyContent='space-between'
        marginBottom='.25rem'
      >
        <Typography variant='h2'>
          {texts('title')}
        </Typography>
        <Button
          variant='contained'
          onClick={() => {
            navigate('/schedule/exceptions/create')
          }}
          sx={{
            width: '17.5rem'
          }}
        >
          {texts('add_exception_button_label')}
        </Button>
      </Grid>
    </Grid>
  )
}