import { Button, ButtonProps } from '@mui/material'

export default function PillButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      sx={{
        height: '2rem !important',
        borderRadius: '1rem !important',
        ...props.sx
      }}
    >
      {props.children}
    </Button>
  )
}

