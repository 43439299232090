import * as Yup from 'yup'
import { TFunction, Namespace } from 'i18next'
import { passwordAllowedSpecialCharactersRegex, passwordRequirementsRegex, emailRegex } from './regexp'

export function passwordFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    password: Yup.string()
      .required(t('validations:field_required'))
      .matches(passwordAllowedSpecialCharactersRegex, t('validations:password_special_characters'))
      .min(8, t('validations:password_min_length'))
      .matches(passwordRequirementsRegex, t('validations:password_requirements'))
      .max(32, t('validations:password_max_length'))
  })
}

export function emailFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    email: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .matches(emailRegex, t('validations:email'))
  })
}

export function loginValidation(t: TFunction<Namespace>) {
  return Yup.object({
    email: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .matches(emailRegex, t('validations:email')),
    password: Yup.string()
      .required(t('validations:field_required'))
  })
}
