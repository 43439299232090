import { TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'

export default function TextField(props: TextFieldProps) {
  const [field, meta] = useField(props.name!)

  const configTextField: TextFieldProps = {
    ...field,
    ...props,
    ...(props.onChange ? {
      onChange: (event) => {
        props.onChange?.(event)
        field.onChange(event)
      }
    } : {}),
    fullWidth: true,
    variant: 'outlined'
  }


  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return (
    <MuiTextField {...configTextField} />
  )
}