import { SvgIcon, SvgIconProps } from '@mui/material'

export default function DeleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='13px'
      height='16px'
      viewBox='0 0 13 16'
    >
      <g id="deleteIcon">
        <path d="M11.6357619,4.36341072 C11.234121,4.36341072 10.9085268,4.68900497 10.9085268,5.09064584 L10.9085268,13.2291341 C10.8651034,13.9946038 10.2125831,14.5818721 9.4467842,14.5447024 L3.64344795,14.5447024 C2.87764905,14.5818721 2.2251287,13.9946038 2.18170536,13.2291341 L2.18170536,5.09064584 C2.18170535,4.68900498 1.8561111,4.36341072 1.45447024,4.36341072 C1.05282938,4.36341072 0.727235119,4.68900498 0.727235119,5.09064584 L0.727235119,13.2291341 C0.77045336,14.7980787 2.07435502,16.0366227 3.64344795,15.9991726 L9.4467842,15.9991726 C11.0158771,16.0366227 12.3197788,14.7980787 12.362997,13.2291341 L12.362997,5.09064584 C12.362997,4.68900497 12.0374028,4.36341072 11.6357619,4.36341072 Z" id="Path"></path>
        <path d="M12.362997,2.18170536 L9.45405655,2.18170536 L9.45405655,0.727235119 C9.45405655,0.325594253 9.1284623,0 8.72682143,0 L4.36341072,0 C3.96176985,0 3.6361756,0.325594253 3.6361756,0.727235119 L3.6361756,2.18170536 L0.727235119,2.18170536 C0.325594253,2.18170536 0,2.50729961 0,2.90894048 C0,3.31058134 0.325594253,3.6361756 0.727235119,3.6361756 L12.362997,3.6361756 C12.7646379,3.6361756 13.0902321,3.31058134 13.0902321,2.90894048 C13.0902321,2.50729961 12.7646379,2.18170536 12.362997,2.18170536 Z M5.09064584,2.18170536 L5.09064584,1.45447024 L7.99958631,1.45447024 L7.99958631,2.18170536 L5.09064584,2.18170536 Z" id="Shape"></path>
        <path d="M5.81788095,11.6357619 L5.81788095,6.54511607 C5.81788095,6.14347521 5.4922867,5.81788095 5.09064584,5.81788095 C4.68900497,5.81788095 4.36341072,6.14347521 4.36341072,6.54511607 L4.36341072,11.6357619 C4.36341072,12.0374028 4.68900497,12.362997 5.09064584,12.362997 C5.4922867,12.362997 5.81788095,12.0374028 5.81788095,11.6357619 Z" id="Path"></path>
        <path d="M8.72682143,11.6357619 L8.72682143,6.54511607 C8.72682143,6.14347521 8.40122718,5.81788095 7.99958631,5.81788095 C7.59794545,5.81788095 7.27235119,6.14347521 7.27235119,6.54511607 L7.27235119,11.6357619 C7.27235119,12.0374028 7.59794545,12.362997 7.99958631,12.362997 C8.40122718,12.362997 8.72682143,12.0374028 8.72682143,11.6357619 Z" id="Path"></path>
      </g>
    </SvgIcon>
  )
}