import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { useFormikContext } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useGetCurrentLocation from '../../../hooks/location/useGetCurrentLocation'
import { LocationFormData } from '../../../types/forms/data'

const MapContainer = styled(Box)({
  img: {
    position: 'absolute'
  }
})

export default function MapSection() {
  const texts = useTranslation('locationPage').t

  const { uuid } = useParams<{ uuid?: string }>()

  const { values, setFieldValue, setFieldTouched, touched } = useFormikContext<LocationFormData>()
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(values.coordinates)

  useGetCurrentLocation(uuid != null, (coords) => {
    if (uuid == null && !touched.coordinates?.lat && !touched.coordinates?.lng) {
      setFieldValue('coordinates', {
        lng: coords.longitude,
        lat: coords.latitude
      })
      setFieldTouched('coordinates.lat', true, false)
      setFieldTouched('coordinates.lng', true, false)
      setCenter({
        lng: coords.longitude,
        lat: coords.latitude
      })
    }
  })

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    language: 'pl',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
  })

  return (
    <>
      <Typography
        variant='body1'
        fontWeight={500}
        marginBottom='.5rem'
      >
        {texts('map_label')}
      </Typography>
      {
        isLoaded 
        && <MapContainer height='13.75rem'>
            <GoogleMap
              mapContainerStyle={{ height: '13.75rem' }}
              center={center}
              zoom={12}
              options={{ 
                keyboardShortcuts: false,
                fullscreenControl: false,
                clickableIcons: false,
                mapTypeControl: false,
                streetViewControl: false
              }}
              onClick={(e: google.maps.MapMouseEvent) => {
                if (e.latLng != null) {
                  setFieldValue('coordinates', {
                    lng: e.latLng!.lng(),
                    lat: e.latLng!.lat()
                  })
                  setFieldTouched('coordinates.lat', true, false)
                  setFieldTouched('coordinates.lng', true, false)
                }
              }}
            >
              <Marker position={values.coordinates} />
            </GoogleMap>
          </MapContainer>
      }
    </>
  )
}