import ClearIcon from '@mui/icons-material/Clear'
import { Autocomplete, TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { AutocompleteBaseOption } from '../../types/common'

type Props<T> = {
  name: string
  label: string
  placeholder?: string
  shrink?: boolean
  options: T[]
  disabled?: boolean
  limitTags?: number
  getOptionLabel?: (option: T) => string
}

export default function FormikMultiAutocomplete<T extends AutocompleteBaseOption = AutocompleteBaseOption>(props: Props<T>) {
  const texts = useTranslation('common').t
  const [field, meta, helpers] = useField<T[]>(props.name)

  return (
    <Autocomplete<T, true, false, false>
      noOptionsText={texts('no_options_placeholder')}
      multiple
      filterSelectedOptions
      isOptionEqualToValue={(option: T, value: T) => option.value === value.value}
      disabled={props.disabled}
      value={field.value}
      options={props.options}
      getOptionLabel={(option: T | string) => {
        return typeof option === 'string'
          ? option
          : (props.getOptionLabel?.(option) ?? option.label)
      }}
      onChange={(event, value: T[]) => {
        helpers.setValue(value)
      }}
      // any as props type because type does not include key field (which exists in props)
      renderOption={(props: any, option) => (
        <li {...props} key={option.value}>
          {/* label from key should use getOptionLabel result*/}
          {/* fallthrough to option.label just in case */}
          {props.key ?? option.label} 
        </li>
      )}
      clearIcon={<ClearIcon />}
      limitTags={props.limitTags}
      renderInput={(params: TextFieldProps) => (
        <MuiTextField
          {...params}
          name={props.name}
          placeholder={field.value.length > 0 ? undefined : props.placeholder}
          onBlur={field.onBlur}
          label={props.label}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && !!meta.error ? meta.error : ' '}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...(props.shrink != null ? { shrink: props.shrink } : {})
          }}
        />
      )}
    />
  )
}