import { Theme } from '@mui/material'
import { createTheme, ThemeOptions } from '@mui/material/styles'
import { plPL } from '@mui/x-data-grid-pro'
import type {} from '@mui/lab/themeAugmentation'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    badge: React.CSSProperties
    menuLabel: React.CSSProperties
    menuItem: React.CSSProperties
    minorButton: React.CSSProperties
    textButton: React.CSSProperties
    inputHint: React.CSSProperties
    subtext: React.CSSProperties
    error: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    badge?: React.CSSProperties
    menuLabel?: React.CSSProperties
    menuItem?: React.CSSProperties
    minorButton?: React.CSSProperties
    textButton?: React.CSSProperties
    inputHint?: React.CSSProperties
    subtext?: React.CSSProperties
    error?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    badge: true
    menuLabel: true
    menuItem: true
    minorButton: true
    textButton: true
    inputHint: true
    subtext: true
    error: true
  }
}

const palette: Theme = createTheme({
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#5C0F8B'
    },
    error: {
      main: '#E02020'
    },
  }
})

const typography: ThemeOptions = {
  typography: {
    subtitle1: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      lineHeight: '1.875rem',
      fontSize: '1.5rem',
      color: 'rgba(102 102 102 / 0.5)',
    },
    body1: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.25rem'
    },
    body2: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '.75rem',
      lineHeight: '1.25rem'
    },
    h1: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      lineHeight: '2.375rem',
      fontSize: '2rem',
    },
    h2: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      lineHeight: '2.125rem',
      fontSize: '1.75rem',
    },
    h3: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      lineHeight: '1.75rem',
      fontSize: '1.5rem',
    },
    h4: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      lineHeight: '1.25rem',
      fontSize: '1.25rem',
    },
    badge: {
      fontFamily: 'Roboto',
      color: palette.palette.primary.main,
      fontWeight: 700,
      fontSize: '0.75rem'
    },
    menuLabel: {
      fontFamily: 'Roboto',
      color: 'rgba(187 187 187 / 0.5)',
      fontWeight: 700,
      fontSize: '0.75rem'
    },
    subtext: {
      fontFamily: 'Roboto',
      color: 'rgba(187 187 187)',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '1rem'
    },
    menuItem: {
      fontFamily: 'Roboto',
      color: palette.palette.primary.main,
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.25rem'
    },
    minorButton: {
      fontFamily: 'Roboto',
      color: palette.palette.primary.main,
      fontWeight: 500,
      fontSize: '.875rem'
    },
    button: {
      fontFamily: 'Roboto',
      color: 'white',
      fontWeight: 700,
      fontSize: '1rem'
    },
    textButton: {
      fontFamily: 'Roboto',
      color: palette.palette.secondary.main,
      fontWeight: 700,
      fontSize: '1rem'
    },
    inputHint: {
      fontFamily: 'Roboto',
      color: '#888888',
      fontWeight: 400,
      fontSize: '1rem'
    },
    error: {
      fontFamily: 'Roboto',
      color: palette.palette.error.main,
      fontWeight: 400,
      fontSize: '.75rem',
      marginBlock: '.875rem',
      marginTop: '.1875rem'
    }
  }
}

const components: ThemeOptions = {
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          '.MuiSvgIcon-root': {
            color: '#fff',
            fontSize: '1.5rem',
          },
          '.MuiDivider-root': {
            backgroundColor: '#BCBCBC',
            borderBottomWidth: 0,
            borderTopWidth: '1px'
          },
          '.MuiListItem-root': {
            '.Mui-selected': {
              backgroundColor: 'rgba(255 255 255 / 0.3) !important'
            }
          },
          '.MuiListItemButton-root': {
            height: '3rem',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '1.25rem',
            color: 'white',
            borderRadius: '0.3125rem',
            '.MuiListItemIcon-root': {
              minWidth: 0,
              padding: 0,
              color: 'white',
            }
          }
        },
        paper: {
          marginTop: '3.5rem',
          height: 'calc(100% - 3.5rem)',
          backgroundColor: palette.palette.primary.main,
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            width: '0.4rem'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(255,255,255,.25)',
            borderRadius: '0.2rem'
          },
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderWidth: '0.0625px',
          color: '#BCBCBC'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          fontWeight: 500,
          color: palette.palette.secondary.main
        }
      }
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: () => ({
          color: 'white'
        }),
        root: {
          fontWeight: 700,
          '&.MuiLoadingButton-loading': {
            backgroundColor: palette.palette.primary.main
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: '1.125rem'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#ffffff',
          '&.Mui-disabled': {
            color: '#ffffff'
          }
        },
        outlined: {
          borderWidth: '0.0625rem',
          borderColor: '#000000',
          borderRadius: '0.5rem',
          padding: '0.3125rem 1rem 0.3125rem 1rem'
        },
        text: {
          fontFamily: 'Roboto',
          color: palette.palette.secondary.main,
          fontWeight: 700,
          fontSize: '1rem',
          padding: 0,
          width: 'max-content',
          display: 'inline',
          minWidth: 0,
          ':hover': {
            backgroundColor: 'transparent'
          }
        },
        root: {
          fontWeight: 700,
          fontSize: '1rem',
          textTransform: 'none',
          borderRadius: '0.5rem',
          '&.Mui-disabled': {
            backgroundColor: '#bbbbbb',
          }
        }
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#888888',
          '&.Mui-focused': {
            color: palette.palette.secondary.main,
            '&.Mui-error': {
              color: palette.palette.error.main
            },
          },
          '&.MuiFormLabel-filled': {
            '&.Mui-error': {
              color: palette.palette.error.main
            },
            '&.Mui-focused': {
              color: palette.palette.secondary.main,
            },
            '&.Mui-error.Mui-focused': {
              color: palette.palette.error.main
            }
          },
        },
        shrink: {
          fontWeight: 700
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '1rem',
          '.MuiOutlinedInput-root': {
            borderRadius: '0.5rem',

            '&.Mui-error': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: palette.palette.error.main,
              },
            },
            '&.Mui-focused': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: palette.palette.secondary.main,
              },
              '&.Mui-error': {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: palette.palette.error.main,
                },
              }
            },
            ':hover': {
              '&.Mui-error': {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: palette.palette.error.main,
                },
              },
              '&.Mui-focused': {
                '&.Mui-error': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: palette.palette.error.main,
                  },
                },
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: palette.palette.secondary.main,
                },
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#bbbbbb',
              },
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#bbbbbb',
            },
            '.MuiOutlinedInput-input': {
              fontSize: '1rem',
              letterSpacing: 0.8,
              color: '#444444',
              '&::placeholder': {
                color: '#888888',
                letterSpacing: 0.8,
                opacity: 1,
              }
            }
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '.MuiPaper-root': {
            padding: '0',
            borderRadius: '0.5rem',
            boxShadow: '0px 1px 3px rgba(0,0,0, 0.2), 0px 2px 1px -1px rgba(0,0,0, 0.12), 0px 1px 1px rgba(0,0,0, 0.14)'
          },
          '.MuiMenuItem-root': {
            padding: '1rem',
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem'
          }
        },
        list: {
          padding: 0
        },
        paper: {
          boxShadow: 'none'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          position: 'relative',
          height: '3.5rem',
          backgroundColor: '#ffffff',
          boxShadow: '0 0.125rem 1rem 0.125rem rgba(0, 0, 0, 0.16)',
          display: 'flex',
          flexDirection: 'row',
          padding: '0 1rem 0 1rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          '.MuiButton-root': {
            width: '18rem',
            height: '1.875rem'
          }
        },
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '1rem',
          ':last-child': {
            padding: '1rem'
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingInline: 0
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingInline: 0,
          paddingTop: 0
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiPaper-root': {
            padding: '2rem'
          }
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: 'unset'
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary'
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '.MuiDataGrid-columnHeader': {
            backgroundColor: '#000',
            '&:focus-within': { outline: 'none' }
          },
          '.MuiDataGrid-columnHeaders': {
            backgroundColor: '#000'
          },
          '.MuiDataGrid-columnHeaderTitleContainer': {
            fontSize: '1rem',
            fontFamily: 'Roboto',
            fontWeight: 700,
            color: '#fff',
            paddingLeft: '.25rem'
          },
          '.MuiDataGrid-filterIcon': {
            color: '#fff'
          },
          '.MuiDataGrid-menuIconButton': {
            color: '#fff'
          },
          '.MuiDataGrid-sortIcon': {
            color: '#fff'
          },
          '.MuiDataGrid-cell': {
            '&:focus-within': { outline: 'none' }
          },
          '.MuiDataGrid-cellContent': {
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '1rem'
          },
          '.MuiDataGrid-columnHeaderTitleContainer > .MuiDataGrid-iconButtonContainer > .MuiButtonBase-root': {
            paddingRight: 0
          },
          '.MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '& .MuiDataGrid-virtualScroller': {
            overflowX: 'hidden !important',
            '&::-webkit-scrollbar': {
              width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            }
          }
        },
        filterForm: {
          padding: '1rem',
          gap: '.5rem',
          '.MuiDataGrid-filterForm': { p: 2 },
          '.MuiTextField-root': {
            marginBottom: 'unset'
          },
          '.MuiDataGrid-filterForm:nth-child(even)': {
            backgroundColor: (theme: Theme) =>
              theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
          },
          '.MuiDataGrid-filterFormLinkOperatorInput': { marginRight: '.125rem' },
          '.MuiDataGrid-filterFormColumnInput': { 
            marginRight: '.125rem', 
            width: '9.375rem',
            '.MuiOutlinedInput-root': {
              borderRadius: '0.5rem',
              '&.Mui-error': {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: palette.palette.error.main,
                },
              },
              '&.Mui-focused': {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: palette.palette.secondary.main,
                },
                '&.Mui-error': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: palette.palette.error.main,
                  },
                }
              },
              ':hover': {
                '&.Mui-error': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: palette.palette.error.main,
                  },
                },
                '&.Mui-focused': {
                  '&.Mui-error': {
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: palette.palette.error.main,
                    },
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: palette.palette.secondary.main,
                  },
                },
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#bbbbbb',
                },
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#bbbbbb',
              },
              '.MuiOutlinedInput-input': {
                fontSize: '1rem',
                letterSpacing: 0.8,
                color: '#444444',
                '&::placeholder': {
                  color: '#888888',
                  letterSpacing: 0.8,
                  opacity: 1,
                }
              }
            }
          },
          '.MuiDataGrid-filterFormOperatorInput': { 
            marginRight: '.125rem',
            '.MuiOutlinedInput-root': {
              borderRadius: '0.5rem',
              '&.Mui-error': {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: palette.palette.error.main,
                },
              },
              '&.Mui-focused': {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: palette.palette.secondary.main,
                },
                '&.Mui-error': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: palette.palette.error.main,
                  },
                }
              },
              ':hover': {
                '&.Mui-error': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: palette.palette.error.main,
                  },
                },
                '&.Mui-focused': {
                  '&.Mui-error': {
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: palette.palette.error.main,
                    },
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: palette.palette.secondary.main,
                  },
                },
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#bbbbbb',
                },
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#bbbbbb',
              },
              '.MuiOutlinedInput-input': {
                fontSize: '1rem',
                letterSpacing: 0.8,
                color: '#444444',
                '&::placeholder': {
                  color: '#888888',
                  letterSpacing: 0.8,
                  opacity: 1,
                }
              }
            }
          },
          '.MuiDataGrid-filterFormValueInput': { width: '12.5rem' },
        }
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          '.MuiToggleButtonGroup-grouped': {
            backgroundColor: '#D8D8D8',
            border: '0',
            width: '2.5rem',
            height: '2.5rem',
            ':hover': {
              backgroundColor: '#D8D8D8'
            },
            ':not(:first-of-type)': {
              marginLeft: 'unset',
              borderRadius: '50%'
            },
            ':not(:last-of-type)': {
              borderRadius: '50%'
            },
            marginRight: '.5rem'
          },
          '.Mui-selected': {
            backgroundColor: `${palette.palette.secondary.main} !important`
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '.MuiTableHead-root': {
            '.MuiTableCell-root': {
              backgroundColor: palette.palette.primary.main,
              color: '#ffffff'
            }
          },
          '> .MuiTableRow-root': {
            '.MuiTableCell-root': {
              backgroundColor: '#ffffff',
              ':first-child': {
                backgroundColor: 'rgba(187, 187, 187, 0.3)'
              }
            } 
          }          
        }
      }
    },
    MuiCircularProgress: {
      defaultProps: {
        disableShrink: true
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          ' &.MuiPickersDay-root': {
            '&.Mui-selected': {
              color: '#fff'
            }
          }
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '.MuiPaper-root': {
            '&::-webkit-scrollbar': {
              width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '0.2rem'
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            }
          }
        }
      }
    }
  }
}

const MainTheme = createTheme(palette, typography, components, plPL)
export default MainTheme
export const maxPageWidth = '64rem'