import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import useCreatePlace from '../../../hooks/data/locations/useCreatePlace'
import useEditPlace from '../../../hooks/data/locations/useEditPlace'
import { mapLocationFormData } from '../../../mapping/locations'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import { LocationFormData } from '../../../types/forms/data'
import { locationFormDataValidation } from '../../../validations/location'
import FormSubsection from './FormSubsection'
import MapSection from './MapSection'
import PhotoSection from './PhotoSection'

type Props = {
  isEditPage: boolean
  initialValues?: LocationFormData
}

const defaultInitialValues: LocationFormData = {
  name: '',
  street: '',
  postalCode: '',
  town: '',
  contactEmail: '',
  contactPhone: '',
  directions: '',
  photo: null,
  coordinates: {
    lng: 21.017532,
    lat: 52.237049
  },
  lockId: ''
}

export default function MainSection(props: Props) {
  const texts = useTranslation('locationPage').t
  const navigate = useNavigate()

  const { uuid } = useParams<{ uuid?: string }>()

  const createMutation = useCreatePlace()
  const editMutation = useEditPlace(uuid ?? '')

  const handleSubmit = (data: LocationFormData) => {
    if (props.isEditPage) {
      editMutation.mutate({ data: mapLocationFormData(data) }, {
        onError: (error) => {
          if (isDataError(error, [DataSubcodes.PLACE_NOT_FOUND, DataSubcodes.PLACE_REMOVED])) {
            navigate(-1)
          }
        }
      })
    } else {
      createMutation.mutate({ data: mapLocationFormData(data) }, {
        onSuccess: () => {
          navigate('/locations')
        }
      })
    }
  }

  return (
    <Grid
      container
      justifyContent='center'
    >
      <Card 
        sx={{ 
          width: '80%',
          '.MuiCardContent-root': {
            padding: '2rem',
            ':last-child': {
              padding: '2rem'
            }
          }
        }}
      >
        <CardContent>
          <Formik<LocationFormData>
            initialValues={props.initialValues ?? defaultInitialValues}
            onSubmit={handleSubmit}
            validationSchema={locationFormDataValidation(texts)}
          >
            {(formikProps) => (
              <Form>
                <Grid
                  display='grid'
                  gridTemplateColumns='20rem 1fr'
                  marginBottom='1.5rem'
                  columnGap='1.5rem'
                >
                  <Grid
                    item
                    gridArea='1 / 1 / 2 / 2'
                    width='100%'
                  >
                    <PhotoSection />
                  </Grid>
                  <Grid
                    item
                    gridArea='1 / 2 / 2 / 3'
                    width='100%'
                  >
                    <FormSubsection />
                  </Grid>
                  <Grid
                    item
                    gridArea='2 / 1 / 3 / -1 '
                    width='100%'
                  >
                    <MapSection />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent='flex-end'
                >
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    disabled={!formikProps.dirty || !formikProps.isValid}
                    loading={createMutation.isLoading}
                    sx={{
                      width: '10rem'
                    }}
                  >
                    {texts('save_button')}
                  </LoadingButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Grid>
  )
}