import { Grid } from '@mui/material'
import { getGridSingleSelectOperators, GridColDef, GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DecodedValueMap, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import useGetAccountList from '../../../hooks/data/accounts/useGetAccountList'
import { queryNames } from '../../../hooks/queries'
import { Account, AccountListSearchParams } from '../../../types/account'
import { AccountStatus } from '../../../types/common'
import { accountListPageSizes, contentContainerId } from '../../../utils/const'
import ListConnectionError from '../../common/ListConnectionError'
import TableBadge from '../../common/TableBadge'
import DataGrid from '../../customMui/DataGrid'
import { mapAccountListFilterModel, mapAccountListSortModel } from '../../customMui/utils'
import UpdateAccountActiveStateDialog from '../../dialogs/UpdateAccountActiveStateDialog'
import UpdateAccountNameDialog from '../../dialogs/UpdateAccountNameDialog'
import MenuButton, { EventType } from './MenuButton'
import NoAccountsFound from './NoAccountsFound'

function isSearchParamsValid(params: DecodedValueMap<AccountListSearchParams>, columns: GridColDef<Account>[]): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
    && Number.isInteger(params.size)  // must be a number, not a NaN
    && accountListPageSizes.includes(params.size) // selected size must be included in defined list
    && (params.status == null || params.status in AccountStatus) // status must be null or included in enum
    && (
      params.order == null || params.order === '' // no sort order defined
      || (
        columns.find(c => c.field === params.order.split(':')[0]) != null // first part of order string matches column
        && columns.find(c => c.field === params.order.split(':')[0])?.sortable === true // first part of order string must match sortable column
        && ['asc', 'desc'].includes(params.order.split(':')[1]?.toLowerCase()) // second part of sort string must be a sort order string
      ) 
    )
}

const defaultOrder = 'createdAt:desc'

export default function MainSection() {
  const texts = useTranslation(['common', 'accountList']).t
  const queryClient = useQueryClient()

  const [isEditNameDialogOpen, setIsEditNameDialogOpen] = useState<boolean>(false)
  const [isActiveStateDialogOpen, setIsActiveStateDialogOpen] = useState<boolean>(false)
  const [editedAccount, setEditedAccount] = useState<Account | null>(null)

  const [searchParams, setSearchParams] = useQueryParams<AccountListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    size: withDefault(NumberParam, 10),
    order: withDefault(StringParam, defaultOrder),
    status: withDefault(NumberParam, undefined),
    search: withDefault(StringParam, undefined)
  })

  const columns = texts('accountList:table_columns', { returnObjects: true })
  const accountListColumns: GridColDef<Account>[] = useMemo(() => [
    {
      field: columns[0].name,
      headerName: columns[0].field,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      flex: 3
    },
    {
      field: columns[1].name,
      headerName: columns[1].field,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      flex: 3
    },
    {
      field: columns[2].name,
      headerName: columns[2].field,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      flex: 2.2,
      renderCell: (props) => (
        <>
          {
            props.row.roles.map(r => (
              <TableBadge 
                label={texts('common:account_role_label', { role: r })}
                color={texts('accountList:account_role_badge_color', { role: r })}
              />
            ))
          }
        </>
      )
    },
    {
      field: columns[3].name,
      headerName: columns[3].field,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      flex: 3.5,
      valueGetter: (props) => moment(props.row.createdAt).format('DD.MM.YYYY HH:mm')
    },
    {
      field: columns[4].name,
      headerName: columns[4].field,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      flex: 4,
      valueGetter: (props) => props.row.lastActivity ? moment(props.row.lastActivity).format('DD.MM.YYYY HH:mm') : '-'
    },
    {
      field: columns[5].name,
      headerName: columns[5].field,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      type: 'singleSelect',
      filterOperators: getGridSingleSelectOperators().filter(({ value }) => ['is'].includes(value)),
      flex: 2.5,
      renderCell: (props) => (
        <TableBadge 
          label={texts('common:account_status_label', { status: props.row.status })}
          color={texts('accountList:account_status_badge_color', { status: props.row.status })}
        />
      ),
      valueOptions: [
        {
          value: AccountStatus.ACTIVE,
          label: texts('common:account_status_label', { status: AccountStatus.ACTIVE })
        },
        {
          value: AccountStatus.INACTIVE,
          label: texts('common:account_status_label', { status: AccountStatus.INACTIVE })
        },
        {
          value: AccountStatus.WAITING_FOR_ACTIVATION,
          label: texts('common:account_status_label', { status: AccountStatus.WAITING_FOR_ACTIVATION })
        }
      ]
    },
    {
      field: 'actions',
      headerName: '\u00A0',
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      flex: 0.5,
      renderCell: (props) => (
        <MenuButton 
          status={props.row.status}
          onItemClick={(type) => {
            setEditedAccount(props.row)
            switch (type) {
              case EventType.CHANGE_STATE:
                setIsActiveStateDialogOpen(true)
                break
              case EventType.EDIT_NAME:
                setIsEditNameDialogOpen(true)
                break
            }
          }}
        />
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])

  const { data, isFetching, isError } = useGetAccountList({
    limit: searchParams.size,
    offset: (searchParams.page - 1) * searchParams.size,
    search: searchParams.search ? decodeURI(searchParams.search) : undefined,
    order: searchParams.order,
    status: searchParams.status != null && searchParams.status in AccountStatus ? searchParams.status : undefined,
    enabled: isSearchParamsValid(searchParams, accountListColumns),
  })

  const handleSortChange = (model: GridSortModel) => {
    if (searchParams.order === defaultOrder && model.length === 0) {
      return setSearchParams(
        {
          order: 'createdAt:asc',
          page: 1
        }, 'replaceIn'
      )
    }
    setSearchParams(
      {
        order: model.length > 0
          ? model.map(entry => `${entry.field}:${entry.sort}`).join(',')
          : defaultOrder,
        page: 1
      }, 'replaceIn'
    )
  }

  const handleFilterChange = (model: GridFilterModel) => {
    const filterChanged = (model.items.length === 0 || model.items[0].value != null) && searchParams.status !== model.items[0].value
    setSearchParams({
      status: model.items.length > 0 && model.items[0].value != null && model.items[0].columnField === 'status'
        ? model.items[0].value
        : undefined,
        ...(filterChanged ? { page: 1 } : {})
      }, 'replaceIn')
  }

  useEffect(() => {
    document.getElementById(contentContainerId)?.scrollTo(0, 0)
  }, [searchParams.page])

  useEffect(() => {
    if (!isSearchParamsValid(searchParams, accountListColumns)) {
      setSearchParams({
        page: 1,
        size: accountListPageSizes[0],
        order: 'createdAt:desc',
        search: undefined,
        status: undefined
      }, 'replaceIn')
      return
    }

    if (searchParams.page === 1) {
      setSearchParams({
        page: 1
      }, 'replaceIn')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountListColumns])

  if (!isSearchParamsValid(searchParams, accountListColumns)) {
    return <></>
  }

  if (isError) {
    return (
      <Grid marginTop='10rem' width='100%'>
        <ListConnectionError 
            onReload={() => {
              queryClient.resetQueries([
                queryNames.getAccountList,
                searchParams.order,
                searchParams.search,
                searchParams.size,
                (searchParams.page - 1) * searchParams.size
              ])
            }}
          />
      </Grid> 
    )
  }

  const isPageNotFound = searchParams.page > 1 && !data?.accounts?.length

  return (
    <>
      <UpdateAccountActiveStateDialog 
        open={isActiveStateDialogOpen}
        account={editedAccount}
        onClose={() => { setIsActiveStateDialogOpen(false) }}
      />
      <UpdateAccountNameDialog 
        open={isEditNameDialogOpen}
        account={editedAccount}
        onClose={() => { setIsEditNameDialogOpen(false) }}
      />
      <Grid 
        container 
        flexDirection='column'
        width='100%'
        marginY='1.5rem'
        gap='1.5rem'
      >
        <DataGrid
          columns={accountListColumns}
          rows={data?.accounts ?? []}
          rowCount={data?.totalCount ?? 0}
          noRowsOverlay={NoAccountsFound}
          rowHeight={40}
          loading={isFetching || isPageNotFound}
          pageSize={searchParams.size}
          onPageSizeChange={(num) => { 
            setSearchParams({ size: num }, 'replaceIn') 
            queryClient.resetQueries([
              queryNames.getAccountList,
              searchParams.order,
              searchParams.search ? decodeURI(searchParams.search) : undefined,
              num,
              (searchParams.page - 1) * searchParams.size,
              searchParams.status != null && searchParams.status in AccountStatus ? searchParams.status : undefined
            ])
          }}          
          onPageChange={(page) => { 
            setSearchParams({ page: page + 1 }, 'replaceIn')
            queryClient.resetQueries([
              queryNames.getAccountList,
              searchParams.order,
              searchParams.search ? decodeURI(searchParams.search) : undefined,
              searchParams.size,
              page * searchParams.size,
              searchParams.status != null && searchParams.status in AccountStatus ? searchParams.status : undefined
            ])
          }}
          rowsPerPageOptions={accountListPageSizes}
          page={searchParams.page - 1}
          getRowId={(row) => row.uuid}
          onSortModelChange={handleSortChange}
          onFilterModelChange={handleFilterChange}
          sortModel={mapAccountListSortModel(searchParams.order)}
          filterModel={mapAccountListFilterModel(searchParams.status)}
        />
      </Grid>
    </>
  )
}