import { TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'
import { useRef, useEffect } from 'react'

function ignoreWheel(e: Event) {
  e.preventDefault()
}

const allowedSpecialKeys = [
  'Backspace',
  'ArrowLeft',
  'ArrowRight'
]

export default function NumberField(props: TextFieldProps & { min: number, max: number }) {
  const [field, meta, helpers] = useField(props.name!)

  const configTextField: TextFieldProps = {
    ...field,
    ...props,
    onChange: (event) => {
      props.onChange?.(event)
      field.onChange(event)
      const value = parseInt(event.target.value)
      if (value < props.min) {
        helpers.setValue(props.min)
      } else if (value > props.max) {
        helpers.setValue(props.max)
      }
    },
    type: 'number',
    InputProps: { 
      ...props.InputProps,
      inputProps: { 
        ...props.InputProps?.inputProps,
        min: props.min, 
        max: props.max,
        onKeyDown: (ev) => {
          if (!ev.key.match(/^[0-9]+$/) && !allowedSpecialKeys.includes(ev.key)) {
            ev.preventDefault()
          }
        }
      } 
    },
    fullWidth: true,
    variant: 'outlined'
  }

  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    let fieldRef: HTMLInputElement | null = null
    if (ref.current) {
      ref.current.addEventListener('wheel', ignoreWheel)
      fieldRef = ref.current
    }
    return () => {
      if (fieldRef) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        fieldRef.removeEventListener('wheel', ignoreWheel)
      }
    }
  }, [ref])

  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return (
    <MuiTextField 
      {...configTextField}
      ref={ref}
    />
  )
}