import { Link as MuiLink } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

type Props = {
  to: string
  children?: React.ReactNode | React.ReactNode[]
}

export default function Link(props: Props) {
  return (
    <MuiLink 
      component={RouterLink}
      to={props.to}
    >
      {props.children}
    </MuiLink>
  )
}