import { Grid, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LicenseInfo } from '@mui/x-license-pro'
import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './App.css'
import MainTheme from './components/themes/MainTheme'
import Dashboard from './pages/Dashboard'
import { forceLogOut, LoginState } from './redux/storageToolkit'
import store from './redux/store'
import { getUserUuid } from './services/storage/storageService'
import ReactGA from 'react-ga4'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { pl } from 'date-fns/locale'

export const ROUTER_BASE_NAME = '/'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY ?? '')

if (process.env.REACT_APP_GA_ENABLED === 'true' && process.env.REACT_APP_GA_TRACKING_ID) {
  ReactGA.initialize([{ trackingId: process.env.REACT_APP_GA_TRACKING_ID, gtagOptions: { send_page_view: false }}])
}

export default function App() {
  const navigate = useNavigate()
  const texts = useTranslation(['successes', 'errors']).t

  const loginStateRef = useRef<LoginState | null>(null)

  useEffect(() => {
    console.log(process.env.REACT_APP_VERSION)
  }, [])

  const handleLoginStateChange = () => {
    const previousValue = loginStateRef.current
    loginStateRef.current = store.getState().storage.loginState

    if (previousValue === loginStateRef.current) { //stan dla nas sie nie zmienił
      return
    }

    switch (store.getState().storage.loginState) {
      case LoginState.LOGGED:
        break
      case LoginState.FORCED_LOGGED_OUT:
        toast.error(texts('errors:force_logout'))
        navigate('/login')
        break
      case LoginState.LOGGED_OUT:
        toast.success(texts('successes:logout'))
        navigate('/login')
        break
    }
  }

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      handleLoginStateChange()
    })

    const handleCrossTabLogout = () => {
      if (store.getState().storage.loginState === LoginState.LOGGED && !getUserUuid()) {
        store.dispatch(forceLogOut())
      }
    }
    window.addEventListener('storage', handleCrossTabLogout) 

    return () => {
      window.removeEventListener('storage', handleCrossTabLogout)
      unsubscribe()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={MainTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
        <Helmet>
          <meta key='robots' name='robots' content='noindex,nofollow' />
          <meta key='googlebot' name='googlebot' content='noindex,nofollow' />
        </Helmet>
        <Grid 
          sx={{
            '& .toast': {
              'div:first-child': {
                marginTop: '.25rem'
              }
            }
          }}
        >
          <Toaster
            position='top-right'
            containerStyle={{
              fontFamily:'Roboto',
              top:'5rem'
            }}
            toastOptions={{
              className: 'toast',
              style: {
                alignItems: 'flex-start'
              },
              success: { duration: 4000 },
              error: { duration: 4000 }
            }}
          />
        </Grid>
        <Dashboard/>
      </LocalizationProvider>
    </ThemeProvider>
  )
}