import { Card, CardActions, CardContent, Grid, Typography } from '@mui/material'
import { EventPattern } from '../../../types/eventPatterns'
import { useTranslation } from 'react-i18next'
import { PatternRepeatType } from '../../../types/common'
import TileMenuSection from './TileMenuSection'
import RemoveEventPatternDialog from '../../dialogs/RemoveEventPatternDialog'
import { useState } from 'react'
import moment from 'moment'

type Props = {
  eventPattern: EventPattern
}

export default function Tile(props: Props) {
  const texts = useTranslation('eventPatternList').t
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  return (
    <>
      <RemoveEventPatternDialog 
        open={isDialogOpen}
        eventPattern={props.eventPattern}
        onClose={() => { setIsDialogOpen(false) }}
      />
      <Card 
        sx={{
          display: 'flex',
          position: 'relative'
        }}
      >
        <CardContent sx={{ flexGrow: 1 }}>
          <Grid
            container
            flexDirection='column'
            gap='.5rem'
          >
            <Typography
              variant='body1'
              fontWeight={700}
            >
              {
                texts('tile_date_time_row', { 
                  date: moment(props.eventPattern.pattern.startedAt, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                  startTime: props.eventPattern.pattern.startTime,
                  endTime: props.eventPattern.pattern.endTime
                })
              }
            </Typography>
            <Typography
              variant='body1'
              fontStyle='italic'
              fontWeight={500}
            >
              {
                texts('tile_pattern_row', { 
                  repeatType: texts('pattern_repeat_type_label', { type: props.eventPattern.pattern.repeatType }),
                  weekDays: props.eventPattern.pattern.repeatType === PatternRepeatType.EVERY_WEEK
                    ? props.eventPattern.pattern.weekDays.map(d => texts('week_day_label', { day: d })).join(',')
                    : '',
                  until: props.eventPattern.pattern.repeatType !== PatternRepeatType.NO_REPEAT
                    ? texts('until_label', { until: moment(props.eventPattern.pattern.endedAt, 'YYYY-MM-DD').format('DD.MM.YYYY') })
                    : ''
                })
              }
            </Typography>
            <Typography variant='body1'>
              {
                texts('tile_place_row', { 
                  place: props.eventPattern.place.name,
                  rooms: props.eventPattern.place.trainingRooms.map(r => r.name).join(', ')
                })
              }
            </Typography>
          </Grid>
        </CardContent>
        <CardActions 
          sx={{ 
            flexDirection: 'column',
            justifyContent: 'flex-start'
          }}
        >
          <TileMenuSection 
            eventPattern={props.eventPattern}
            onRemoveClick={() => { setIsDialogOpen(true) }}
          />
        </CardActions>
      </Card>
    </>
  )
}