import { useTranslation } from 'react-i18next'
import { Grid, Typography, Box, Button } from '@mui/material'
import { useState } from 'react'
import ImageIcon from '../../icons/ImageIcon'
import ImageUploadDialog from '../../dialogs/imageUploadDialog/ImageUploadDialog'
import { useFormikContext } from 'formik'
import EditIcon from '../../icons/EditIcon'
import DeleteIcon from '../../icons/DeleteIcon'
import { LocationFormData } from '../../../types/forms/data'

export default function PhotoSection() {
  const texts = useTranslation('locationPage').t

  const { values, setFieldValue, setFieldTouched } = useFormikContext<LocationFormData>()

  const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false)

  return (
    <>
      <ImageUploadDialog
        open={openUploadDialog}
        aspectRatio={16/9}
        croppedDimensions={{
          width: 800,
          height: 450
        }}
        thumbnailWidth={500}
        onClose={() => { setOpenUploadDialog(false) }}
        onSuccess={(data) => {
          setFieldTouched('photo', true)
          setFieldValue('photo', data)
        }}
      />
      {
        values.photo?.original == null
        ? <Grid
            container
            gap='.5rem'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            sx={{
              width: '20rem',
              height: '11.25rem',
              borderRadius: '.5rem',
              padding: '1rem 2.5rem',
              cursor: 'pointer',
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='2' stroke-dasharray='2%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`
            }}
            onClick={() => { setOpenUploadDialog(true) }}
          >
            <Box 
              fontSize='2.4375rem'
              height='2.4375rem'
              marginBottom='.5rem'
            >
              <ImageIcon fontSize='inherit'/>
            </Box>
            <Typography variant='body1'>
              {texts('photo_tile_title')}
            </Typography>
            <Typography 
              variant='subtext'
              textAlign='center'
            >
              {texts('photo_tile_sub_title')}
            </Typography>
          </Grid>
        : <>
            <Box
              component='img'
              src={values.photo?.original.url}
              sx={{
                width: '20rem',
                height: '11.25rem',
                borderRadius: '.5rem',
              }}
            />
            <Grid
              container
              justifyContent='flex-end'
              gap='1rem'
            >
              <Button
                variant='text'
                onClick={() => { setOpenUploadDialog(true) }}
              >
                <Grid
                  container
                  alignItems='center'
                  gap='.25rem'
                >
                  <EditIcon fontSize='inherit' sx={{ marginTop: '-.125rem' }}/>
                  {texts('change_photo_button_label')}
                </Grid>
              </Button>
              <Button
                variant='text'
                sx={{
                  color: '#E02020'
                }}
                onClick={() => {
                  setFieldTouched('photo', true)
                  setFieldValue('photo', null)
                }}
              >
                <Grid
                  container
                  alignItems='center'
                  gap='.25rem'
                >
                  <DeleteIcon fontSize='inherit' sx={{ marginTop: '-.125rem' }}/>
                  {texts('remove_photo_button_label')}
                </Grid>
              </Button>
            </Grid>
          </>
      }
      
    </>
    
  )
}