import { Grid } from '@mui/material'
import TopSection from '../../../components/eventPatterns/list/TopSection'
import MainSection from '../../../components/eventPatterns/list/MainSection'
import useGaPageView from '../../../hooks/google/useGaPageView'

export default function EventPatternList() {
  useGaPageView('Event pattern list')

  return (
    <Grid width='100%' paddingX='.5rem'>
      <TopSection />
      <MainSection />
    </Grid>
  )
}