import { SvgIcon, SvgIconProps } from '@mui/material'

export default function LocationIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='22px'
      height='24px'
      viewBox='0 0 22 24'
    >
      <g id="LocationIcon" transform="translate(-13.000000, -12.000000)" fill-rule="nonzero">
        <g id="locationIcon" transform="translate(13.000000, 12.000000)">
          <path d="M10.546875,0 C6.32976563,0 2.8125,3.39703125 2.8125,7.734375 C2.8125,9.38446875 3.30834375,10.855875 4.25995313,12.2346563 L9.95498437,21.1212188 C10.2312656,21.5532188 10.8630469,21.552375 11.1387656,21.1212188 L16.8585,12.2044219 C17.789625,10.888125 18.28125,9.34251563 18.28125,7.734375 C18.28125,3.46964063 14.8116094,0 10.546875,0 Z M10.546875,11.25 C8.60845313,11.25 7.03125,9.67279688 7.03125,7.734375 C7.03125,5.79595313 8.60845313,4.21875 10.546875,4.21875 C12.4852969,4.21875 14.0625,5.79595313 14.0625,7.734375 C14.0625,9.67279688 12.4852969,11.25 10.546875,11.25 Z" id="Shape"></path>
          <path d="M16.043625,16.1575781 L12.5031094,21.6929063 C11.5866563,23.12175 9.50198437,23.1170625 8.5899375,21.6942656 L5.04365625,16.1590313 C1.92346875,16.8804375 0,18.2019844 0,19.78125 C0,22.5216562 5.434125,24 10.546875,24 C15.659625,24 21.09375,22.5216562 21.09375,19.78125 C21.09375,18.2008594 19.1676094,16.8786094 16.043625,16.1575781 Z" id="Path"></path>
        </g>
      </g>
    </SvgIcon>
  )
}