import { TextField as MuiTextField } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers-pro'
import { useField } from 'formik'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

type Props = {
  name: string
  label: string
  disabled?: boolean
  shrink?: boolean
  onChange?: (time: Date | null) => void
}

export default function FormikTimePicker(props: Props) {
  const texts = useTranslation('common').t
  const [field, meta, helpers] = useField(props.name)

  return (
    <TimePicker
      value={field.value ? moment(field.value, 'HH:mm').toDate() : null}
      disabled={props.disabled}
      ampm={false}
      onChange={(time) => {
        helpers.setValue(time != null ? moment(time).format('HH:mm') : null)
        helpers.setTouched(true, false)
        props.onChange?.(time)
      }}
      renderInput={({ inputProps, InputLabelProps, ...restParams }) => 
        <MuiTextField 
          {...restParams}
          label={props.label}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && !!meta.error ? meta.error : ' '}
          inputProps={{
            ...inputProps,
            placeholder: texts('time_placeholder')
          }}
          InputLabelProps={{
            ...InputLabelProps,
            ...(props.shrink != null ? { shrink: props.shrink } : {})
          }}
          onKeyDown={(event) => {
            event.preventDefault()
          }}
        />
      }
    />
  )
}