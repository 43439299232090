import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'

type Props = {
  expanded: boolean
  selected?: boolean
  icon: React.ReactNode
  text: string
  onClick: () => void
}

export default function DrawerItem(props: Props) {
  return (
    <ListItem
      disablePadding
      sx={{
        width: props.expanded ? 'calc(100% - 2rem)' : 'calc(100% - 1rem)',
        margin: props.expanded ? '0.5rem 1rem 0 1rem' : '0.5rem 0 0 0.5rem', 
        marginInline: props.expanded ? '1rem' : '0.5rem'
      }}
      onClick={props.onClick}
    >
      <ListItemButton
        disableRipple
        selected={props.selected}
        sx={{
          justifyContent: props.expanded ? 'initial' : 'center',
          padding: props.expanded ? '0.75rem 1.75rem' : '0.5rem 1rem',
        }}
      >
        <ListItemIcon>
          {props.icon}
        </ListItemIcon>
        <ListItemText sx={{ display: props.expanded ? 'block' : 'none' }}>
          <Typography 
            variant='menuItem'
            color='#fff'
          >
            {props.text}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}