import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type Props = {
  uuid: string
  stripeId: string
}

export default function MenuButton(props: Props) {
  const texts = useTranslation('trainerList').t
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Grid
        display='flex'
        justifyContent='center'
        width='100%'
      >
        <IconButton
          aria-label='more'
          id='long-button'
          aria-haspopup='true'
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget)
          }}
          sx={{
            padding: 0
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Grid>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width: '12.5rem'
          },
        }}
      >
        <MenuItem
          disableRipple
          onClick={() => {
            handleClose()
            navigate(`/trainers/${props.uuid}/details`)
          }}
        >
          <Typography variant='body1'>
            {texts('show_details_label')}
          </Typography>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            handleClose()
            window.open(`${process.env.REACT_APP_STRIPE_DASHBOARD_BASE_URL}customers/${props.stripeId}`, '_blank')
          }}
        >
          <Typography variant='body1'>
            {texts('show_customer_stripe_page_label')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

