export enum Codes {
  AUTH = 100,
  DATA = 200,
  INTERNAL = 999
}

export enum AuthSubcodes {
  INVALID_CREDENTIALS = 100,
  UNAUTHORIZED = 101,
  USER_INACTIVE = 102,
  ACCOUNT_REMOVED = 103,
  INVALID_TOKEN = 104,
  INCORRECT_PWD = 105,
  SAME_PWD_AS_PREVIOUS_ONE = 106,
  TOKEN_ALREADY_USED = 107,
  FORBIDDEN = 108,
  EMAIL_TAKEN = 109,
  INVALID_TOKEN_OR_USED = 110
}

export enum DataSubcodes {
  BAD_REQUEST = 200,
  PHOTO_NOT_FOUND = 201,
  PLACE_NAME_TAKEN = 202,
  PLACE_NOT_FOUND = 203,
  PLACE_REMOVED = 208,
  EQUIPMENT_ITEM_NOT_FOUND = 209,
  TRAINING_ROOM_NAME_TAKEN = 210,
  TRAINING_ROOM_NOT_FOUND = 211,
  TRAINING_ROOM_REMOVED = 212,
  ACCOUNT_NOT_FOUND = 213,
  ACCOUNT_REMOVED = 214,
  EXCEPTION_NOT_FOUND = 215,
  TRAINING_ROOMS_NOT_FOUND = 217,
  TRAINING_ROOMS_REMOVED = 218,
  INCORRECT_DATES = 220,
  INCORRECT_TIME = 221,
  EVENT_PATTERN_NOT_FOUND = 222,
  EVENT_PATTERN_REMOVED = 223,
  PATTERN_LOCATION_MISMATCH = 224,
  EXCEPTION_LOCATION_MISMATCH = 225,
  EVENT_NOT_FOUND = 230,
  EMAIL_TAKEN = 231,
  REQUESTED_TRAINER_NOT_FOUND = 232,
  REQUESTED_TRAINER_INACTIVE = 233,
  LOCK_ALREADY_USED = 240,
  NOT_ENOUGH_SEATS = 247
}

export enum InternalSubcodes {
  FORCED_LOGOUT = 100
}