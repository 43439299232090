import { SvgIcon, SvgIconProps } from '@mui/material'

export default function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='13px'
      height='16px'
      viewBox='0 0 13 16'
    >
      <g id="editIcon">
        <path d="M1.56423457,13.0115143 C1.71871975,13.0115143 1.74961678,12.9960657 1.88865345,12.9651687 L4.66938668,12.4090221 C4.96290852,12.3317795 5.25643036,12.1927428 5.48815813,11.961015 L12.223712,5.22546121 C13.2587627,4.1904105 13.2587627,2.41383093 12.223712,1.37878023 L11.6521168,0.776288028 C10.6170661,-0.258762676 8.82503802,-0.258762676 7.78998731,0.776288028 L1.05443348,7.52729038 C0.838154224,7.74356964 0.683669044,8.05253999 0.606426454,8.34606184 L0.0193827711,11.1576921 C-0.0578598188,11.6829417 0.096625361,12.1927428 0.467389792,12.5635072 C0.760911634,12.8570291 1.19347014,13.0115143 1.56423457,13.0115143 Z M2.08948418,8.63958368 L8.82503802,1.88858132 C9.27304504,1.4405743 10.0918165,1.4405743 10.524375,1.88858132 L11.1114187,2.47562501 C11.6366683,3.00087462 11.6366683,3.74240348 11.1114187,4.25220457 L4.39131336,11.0032069 L1.53333753,11.482111 L2.08948418,8.63958368 Z" id="Shape"></path>
        <path d="M12.5929265,14.4643839 L0.787877729,14.4643839 C0.3239937,14.4643839 0.0200696809,14.7683079 0.0200696809,15.232192 C0.0200696809,15.696076 0.403973705,16 0.787877729,16 L12.5289425,16 C12.9928265,16 13.3767305,15.696076 13.3767305,15.232192 C13.3607345,14.7683079 12.9768305,14.4643839 12.5929265,14.4643839 Z" id="Path"></path>
      </g>
    </SvgIcon>
  )
}