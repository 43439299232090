import moment from 'moment'
import { Trans, useTranslation } from 'react-i18next'
import useRemoveEvent from '../../hooks/data/schedule/useRemoveEvent'
import { Event } from '../../types/schedule'
import Dialog from '../customMui/Dialog'

type Props = {
  open: boolean
  event: Event
  onClose: () => void
}

export default function RemoveEventDialog(props: Props) {
  const texts = useTranslation(['dialogs', 'schedule']).t

  const mutation = useRemoveEvent()

  return (
    <Dialog
      open={props.open}
      title={<Trans i18nKey='dialogs:remove_event_title'/>}
      description={
        <Trans 
          i18nKey='dialogs:remove_event_description'
          values={{
            location: props.event.place.name,
            room: props.event.trainingRoom.name,
            interval: `${moment(props.event.start, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY HH:mm')}-${moment(props.event.end, 'YYYY-MM-DD HH:mm').format('HH:mm')}`
          }}
        />
      }
      warning={<Trans i18nKey='dialogs:remove_event_warning'/>}
      closeButtonLabel={texts('dialogs:remove_event_cancel')}
      confirmButtonLabel={texts('dialogs:remove_event_confirm')}
      loading={mutation.isLoading}
      onCloseButtonClick={() => {
        props.onClose()
      }}
      onConfirmButtonClick={() => {
        mutation.mutate({
          uuid: props.event.uuid,
        }, {
          onSettled: props.onClose
        })
      }}
    />
  )
}
