import { SvgIcon, SvgIconProps } from '@mui/material'

export default function CollapseDrawerIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='25px'
      height='20px'
      viewBox='0 0 25 20'
    >
      <path d="M23.75,20 C23.059625,20 22.5,19.440375 22.5,18.75 L22.5,1.25 C22.5,0.5596375 23.059625,0 23.75,0 C24.440375,0 25,0.5596375 25,1.25 L25,18.75 C25,19.440375 24.440375,20 23.75,20 Z"></path>
      <path d="M20,10 C20,10.690375 19.440375,11.25 18.75,11.25 L4.2677625,11.25 L7.1338875,14.116125 C7.6220375,14.60425 7.6220375,15.39575 7.1338875,15.883875 C6.645725,16.372 5.854275,16.372 5.3661125,15.883875 L0.3661125,10.883875 C-0.1220375,10.39575 -0.1220375,9.60425 0.3661125,9.116125 L5.3661125,4.1161125 C5.854275,3.6279625 6.645725,3.6279625 7.1338875,4.1161125 C7.6220375,4.604275 7.6220375,5.395725 7.1338875,5.8838875 L4.2677625,8.75 L18.75,8.75 C19.440375,8.75 20,9.309625 20,10 Z"></path>
    </SvgIcon>
  )
}