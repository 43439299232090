import { PhotoDto, UploadedPhotoDto } from '../api/data/types'
import { Photo } from '../types/common'

export function mapPhotoDto(photo: PhotoDto): Photo {
  return {
    ...photo
  }
}

export function mapUploadedPhotoResponseDto(photo: UploadedPhotoDto): Photo {
  return {
    ...photo
  }
}