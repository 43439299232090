import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { useQueryClient } from '@tanstack/react-query'
import SearchInput from '../../customMui/SearchInput'
import { queryNames } from '../../../hooks/queries'
import { useNavigate } from 'react-router-dom'
import { AccountListSearchParams } from '../../../types/account'

export default function TopSection() {
  const texts = useTranslation('accountList').t
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [searchParams, setSearchParams] = useQueryParams<AccountListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    size: withDefault(NumberParam, 10),
    order: withDefault(StringParam, 'createdAt:desc'),
    status: withDefault(NumberParam, undefined),
    search: withDefault(StringParam, undefined)
  })

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        marginTop='2rem'
      >
        <Typography variant='h2'>
          {texts('title')}
        </Typography>
        <Button
          variant='contained'
          onClick={() => {
            navigate('/accounts/create')
          }}
          sx={{
            width: '17.5rem'
          }}
        >
          {texts('add_access_button_label')}
        </Button>
      </Grid>
      <Grid sx={{ maxWidth: '40%', marginTop: '.875rem' }}>
        <SearchInput
          placeholder={texts('search_input_placeholder')}
          initValue={searchParams.search ? decodeURI(searchParams.search) : undefined}
          onSearch={(search: string) => {
            queryClient.resetQueries([queryNames.getAccountList, search ? encodeURI(search) : undefined])
            setSearchParams({ 
              search: search ? encodeURI(search) : undefined, 
              page: 1,
              status: undefined,
              order: 'createdAt:desc'
            }, 'replaceIn')
          }}
          onClear={() => {
            queryClient.resetQueries([queryNames.getAccountList, undefined])
            setSearchParams({ 
              search: undefined, 
              page: 1,
              status: undefined,
              order: 'createdAt:desc'
            }, 'replaceIn')
          }}
        />
      </Grid>
    </>
  )
}