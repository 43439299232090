import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import { useMemo, useEffect, useState } from 'react'
import { DecodedValueMap, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import useGetSelectedPlacesWithRooms from '../../hooks/data/locations/useGetSelectedPlacesWithRooms'
import useGetEventList from '../../hooks/data/schedule/useGetEventList'
import { mapEventList } from '../../mapping/schedule'
import { ScheduleTableDate, TimeOfDay, ScheduleSearchParams } from '../../types/schedule'
import ControlSection, { DateRangeChangeType } from './ControlSection'
import ScheduleTable from './ScheduleTable'
import Loader from '../common/Loader'
import ConnectionError from '../../pages/Errors/ConnectionError'
import { useQueryClient } from '@tanstack/react-query'
import { queryNames } from '../../hooks/queries'
import { ScheduleFilterFormData } from '../../types/forms/filter'
import { mapLocationRoom, mapSelectedLocationList } from '../../mapping/common'
import { LocationState } from '../../types/common'

function isSearchParamsValid(params: DecodedValueMap<ScheduleSearchParams>): boolean {
  return (params.timesofday == null || params.timesofday?.split(',').filter(e => !(parseInt(e) in TimeOfDay)).length === 0)
    && moment(params.from, 'DD.MM.YYYY').isValid()
    && moment(params.to, 'DD.MM.YYYY').isValid()
    // TODO check locationState value
}

export default function MainSection() {
  const queryClient = useQueryClient()

  const [searchParams, setSearchParams] = useQueryParams<ScheduleSearchParams>({ 
    from: withDefault(StringParam, moment().startOf('isoWeek').format('DD.MM.YYYY')),
    to: withDefault(StringParam, moment().endOf('isoWeek').format('DD.MM.YYYY')),
    places: withDefault(StringParam, undefined),
    rooms: withDefault(StringParam, undefined),
    timesofday: withDefault(StringParam, undefined),
    locationState: withDefault(NumberParam, LocationState.ACTIVE)
  })

  const [initialPlaceFilterExist] = useState<boolean>(searchParams.places != null)

  const dates = useMemo(() => {
    const dates: ScheduleTableDate[] = []
    for (const date = moment(searchParams.from, 'DD.MM.YYYY').startOf('d'); date.isSameOrBefore(moment(searchParams.to, 'DD.MM.YYYY').startOf('d')); date.add(1, 'd')) {
      dates.push({
        date: date.format('DD.MM.YYYY'),
        label: date.format('DD.MM'),
        dayOfWeek: date.isoWeekday()
      })
    }
    return dates
  }, [searchParams.from, searchParams.to])

  const hours = useMemo(() => {
    const hours: string[] = []
    for (const date = moment().startOf('d'); date.isSameOrBefore(moment().endOf('d')); date.add(1, 'h')) {
      hours.push(date.format('HH:mm'))
    }
    return hours
  }, [])

  const eventList = useGetEventList({
    from: moment(searchParams.from, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    to: moment(searchParams.to, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    places: searchParams.places,
    rooms: searchParams.rooms,
    timesofday: searchParams.timesofday,
    enabled: isSearchParamsValid(searchParams),
    activeplaces: searchParams.locationState === LocationState.ACTIVE ? true : undefined
  })

  const selectedPlaces = useGetSelectedPlacesWithRooms(
    searchParams.places != null ? searchParams.places.split(',')  : [],
    (data) => {
      if (
        data.length !== searchParams.places!.split(',').length
        || (searchParams.rooms != null && data.length !== data.map(e => e.trainingRooms).flat().filter(e => searchParams.rooms?.split(',').includes(e.uuid)).length)
      ) {
        setSearchParams({
          from: moment().startOf('isoWeek').format('DD.MM.YYYY'),
          to: moment().endOf('isoWeek').format('DD.MM.YYYY'),
          places: undefined,
          rooms: undefined,
          timesofday: undefined,
          locationState: LocationState.ACTIVE
        })
      }
    },
    searchParams.places != null && initialPlaceFilterExist && isSearchParamsValid(searchParams),
    searchParams.locationState === LocationState.ACTIVE ? true : undefined
  )

  const onDateRangeChange = (type: DateRangeChangeType) => {
    switch (type) {
      case DateRangeChangeType.BACK:
        setSearchParams({
          from: moment(searchParams.from, 'DD.MM.YYYY').subtract(7, 'd').format('DD.MM.YYYY'),
          to: moment(searchParams.to, 'DD.MM.YYYY').subtract(7, 'd').format('DD.MM.YYYY')
        }, 'replaceIn')
        queryClient.resetQueries([
          queryNames.getEventList, 
          moment(searchParams.from, 'DD.MM.YYYY').subtract(7, 'd').format('YYYY-MM-DD'),
          moment(searchParams.to, 'DD.MM.YYYY').subtract(7, 'd').format('YYYY-MM-DD')
        ])
        break
      case DateRangeChangeType.RESET:
        setSearchParams({
          from: moment().startOf('isoWeek').format('DD.MM.YYYY'),
          to: moment().endOf('isoWeek').format('DD.MM.YYYY')
        }, 'replaceIn')
        queryClient.resetQueries([
          queryNames.getEventList, 
          moment().startOf('isoWeek').format('YYYY-MM-DD'),
          moment().endOf('isoWeek').format('YYYY-MM-DD')
        ])
        break
      case DateRangeChangeType.FORWARD:
        setSearchParams({
          from: moment(searchParams.from, 'DD.MM.YYYY').add(7, 'd').format('DD.MM.YYYY'),
          to: moment(searchParams.to, 'DD.MM.YYYY').add(7, 'd').format('DD.MM.YYYY')
        }, 'replaceIn')
        queryClient.resetQueries([
          queryNames.getEventList, 
          moment(searchParams.from, 'DD.MM.YYYY').add(7, 'd').format('YYYY-MM-DD'),
          moment(searchParams.to, 'DD.MM.YYYY').add(7, 'd').format('YYYY-MM-DD')
        ])
        break
    }
  }

  const onFilterChange = (data: ScheduleFilterFormData) => {
    setSearchParams({
      locationState: data.locationState,
      places: data.locations.length > 0 ? data.locations.map(e => e.value).join(',') : undefined,
      rooms: data.rooms.length > 0 ? data.rooms.map(e => e.value).join(',') : undefined,
      timesofday: data.timesOfDay.length > 0 ? data.timesOfDay.join(',') : undefined
    }, 'replaceIn')
  }

  useEffect(() => {
    if (!isSearchParamsValid(searchParams)) {
      setSearchParams({
        from: moment().startOf('isoWeek').format('DD.MM.YYYY'),
        to: moment().endOf('isoWeek').format('DD.MM.YYYY'),
        places: undefined,
        rooms: undefined,
        timesofday: undefined,
        locationState: LocationState.ACTIVE
      }, 'replaceIn')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!isSearchParamsValid(searchParams)) {
    return <></>
  }

  if (selectedPlaces.isFetching) {
    return <Loader />
  }

  if (selectedPlaces.isError) {
    return (
      <ConnectionError
        onRefresh={() => {
          queryClient.resetQueries([queryNames.getSelectedPlacesWithRooms])
        }}
      />
    )
  }

  const isFilterPresent = searchParams.places != null || searchParams.timesofday != null

  const initialFilterValues = isFilterPresent && initialPlaceFilterExist
    ? {
        locationState: searchParams.locationState,
        locations: searchParams.places != null
          ? mapSelectedLocationList(selectedPlaces.data!)
          : [],
        rooms: searchParams.places != null && searchParams.rooms != null
          ? selectedPlaces.data!.map(e => e.trainingRooms).flat().filter(e => searchParams.rooms?.split(',').includes(e.uuid)).map(mapLocationRoom)
          : [],
        timesOfDay: searchParams.timesofday?.split(',').map(e => parseInt(e)) ?? []
      }
    : undefined


  return (
    <Grid marginY='2rem'>
      <Grid
        container
        flexDirection='column'
        alignItems='center'
      >
        <Typography
          variant='h4'
          marginBottom='1rem'
        >
          {`${moment(searchParams.from, 'DD.MM.YYYY').format('DD MMMM')} - ${moment(searchParams.to, 'DD.MM.YYYY').format('DD MMMM YYYY')}`}
        </Typography>
        <ControlSection 
          initValues={initialFilterValues} 
          onDateRangeChange={onDateRangeChange}
          onFilterChange={onFilterChange}
          isFilterPresent={isFilterPresent}
        />
      </Grid>
      <ScheduleTable 
        data={mapEventList(eventList.data ?? [], hours, dates.map(d => d.date))}
        hours={hours}
        dates={dates}
        loading={eventList.isFetching || (searchParams.places != null && selectedPlaces.isFetching)}
      />
    </Grid>
  )
}