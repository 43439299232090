import moment from 'moment'
import { EventPatternBodyDto, EventPatternDto, EventPatternListDto, PatternDto } from '../api/data/types'
import { PatternRepeatType, LocationRoom } from '../types/common'
import { EventPattern, EventPatternList, Pattern } from '../types/eventPatterns'
import { EventPatternFormData } from '../types/forms/data'
import { mapLocationRoom, mapSelectedPlaceDto } from './common'

export function mapPatternDto(data: PatternDto): Pattern {
  return {
    duration: data.duration,
    repeatType: data.repeatType,
    startedAt: data.startedAt,
    endedAt: data.endedAt,
    startTime: data.startTime,
    endTime: data.endTime,
    weekDays: data.weekDays,
  }
}

export function mapEventPatternDto(data: EventPatternDto): EventPattern {
  return {
    uuid: data.uuid,
    pattern: mapPatternDto(data.pattern),
    place: mapSelectedPlaceDto(data.place)
  }
}

export function mapEventPatternListDto(data: EventPatternListDto): EventPatternList {
  return {
    totalCount: data.totalCount,
    eventPatterns: data.eventPatterns.map(mapEventPatternDto)
  }
}

export function mapEventPatternFormData (data: EventPatternFormData): EventPatternBodyDto {
  return {
    place: {
      uuid: data.location!.value,
      trainingRoomUuids: data.rooms.map(e => e.value)
    },
    pattern: {
      duration: data.duration,
      repeatType: data.repeatType,
      startTime: data.startTime!,
      endTime: data.endTime!,
      startedAt: moment(data.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      endedAt: data.repeatType === PatternRepeatType.NO_REPEAT
        ? moment(data.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
        : moment(data.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      weekDays: data.repeatType === PatternRepeatType.EVERY_WEEK
        ? data.weekDays
        : null
    }
  }
}

export function mapEventPattern(data: EventPattern, availableRooms: LocationRoom[]): EventPatternFormData {
  return {
    location: {
      value: data.place.uuid,
      label: data.place.name,
      tz: data.place.tz,
      active: data.place.active,
      trainingRooms: availableRooms.map(mapLocationRoom)
    },
    rooms: data.place.trainingRooms.map(mapLocationRoom),
    startTime: data.pattern.startTime,
    endTime: data.pattern.endTime,
    duration: data.pattern.duration,
    startDate: moment(data.pattern.startedAt, 'YYYY-MM-DD').format('DD.MM.YYYY'),
    endDate: moment(data.pattern.endedAt, 'YYYY-MM-DD').format('DD.MM.YYYY'),
    repeatType: data.pattern.repeatType,
    weekDays: data.pattern.weekDays
  }
}
