import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../components/common/Loader'
import MainSection from '../../components/rooms/list/MainSection'
import TopSection from '../../components/rooms/list/TopSection'
import useGetPlaceDetails from '../../hooks/data/locations/useGetPlaceDetails'
import useGaPageView from '../../hooks/google/useGaPageView'
import { queryNames } from '../../hooks/queries'
import { DataSubcodes } from '../../services/errors/consts'
import { isDataError } from '../../services/errors/utils'
import ConnectionError from '../Errors/ConnectionError'

export default function RoomList() {
  useGaPageView('Training rooms list')
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { uuid } = useParams<{ uuid?: string }>()

  const { isFetching, data, isError } = useGetPlaceDetails(
    uuid ?? '',
    (error) => {
      if (isDataError(error, [DataSubcodes.PLACE_NOT_FOUND, DataSubcodes.PLACE_REMOVED])) {
        navigate(-1)
      }
    }
  )

  if (isFetching) {
    return <Loader />
  }

  if (isError) {
    return (
      <ConnectionError 
        onRefresh={() => {
          queryClient.resetQueries([queryNames.getPlaceDetails, uuid])
        }}
      />
    )
  }

  return (
    <Grid 
      width='100%' 
      paddingX='.5rem' 
      paddingBottom='1rem'
    >
      <TopSection name={data!.name}/>
      <MainSection />
    </Grid>
  )
}