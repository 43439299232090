import { TextField as MuiTextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers-pro'
import { useField } from 'formik'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

type Props = {
  name: string
  label: string
  disabled?: boolean
  shrink?: boolean
  minDate: Date
  maxDate?: Date
}

export default function FormikDatePicker(props: Props) {
  const texts = useTranslation('common').t
  const [field, meta, helpers] = useField(props.name)

  return (
    <DatePicker
      value={field.value ? moment(field.value, 'DD.MM.YYYY').toDate() : null}
      disabled={props.disabled}
      minDate={props.minDate}
      maxDate={props.maxDate}
      onChange={(time) => {
        helpers.setValue(time != null ? moment(time).format('DD.MM.YYYY') : null)
        helpers.setTouched(true, false)
      }}
      onClose={() => { helpers.setTouched(true, true) }}
      renderInput={({ inputProps, InputLabelProps, ...restParams }) => 
        <MuiTextField 
          {...restParams}
          label={props.label}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && !!meta.error ? meta.error : ' '}
          inputProps={{
            ...inputProps,
            placeholder: texts('date_placeholder')
          }}
          InputLabelProps={{
            ...InputLabelProps,
            ...(props.shrink != null ? { shrink: props.shrink } : {})
          }}
          onKeyDown={(event) => {
            event.preventDefault()
          }}
        />
      }
    />
  )
}