import { Namespace, TFunction } from 'i18next'
import moment from 'moment'
import * as Yup from 'yup'

export function exceptionFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    location: Yup.object()
      .nullable()
      .required(t('validations:field_required')),
    rooms: Yup.array()
      .min(1, t('validations:field_required')),
    scheduledAt: Yup.string()
      .nullable()
      .required(t('validations:field_required')),
    unavailableFrom: Yup.string()
      .nullable()
      .when('withTime', {
        is: (withTime: boolean) => withTime,
        then: (schema) => schema
          .nullable()
          .required(t('validations:field_required'))
      }),
    unavailableTo: Yup.string()
      .nullable()
      .when('withTime', {
        is: (withTime: boolean) => withTime,
        then: (schema) => schema
          .nullable()
          .required(t('validations:field_required'))
          .test('end_time_after_start_time', t('validations:end_time_before_start_time'), function (val, ctx) {
            const startTime = this.resolve(Yup.ref<string | null>('unavailableFrom'))
            return moment(startTime, 'HH:mm').isBefore(moment(val, 'HH:mm'))
          })
      }),
  })
}