import { Checkbox as MuiCheckbox, CheckboxProps, FormControl, FormControlLabel, FormHelperText, Grid, SxProps } from '@mui/material'
import { useField } from 'formik'
import { ReactNode } from 'react'

type Props = {
  name: string
  label: ReactNode
  sx?: {
    checkbox?: SxProps
    container?: SxProps
  }
  alignItems?: string
  disabled?: boolean
}

const Checkbox = (props: Props) => {
  const [field, meta, helpers] = useField(props.name)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    helpers.setValue(checked)
    helpers.setTouched(true, false)
  }

  const configCheckbox: CheckboxProps = {
    ...field,
    onChange: handleChange
  }

  return (
    <FormControl 
      error={meta.touched && meta.error != null} 
      sx={{
        marginBottom: '1rem',
        ...props.sx?.container
      }}
    >
      <FormControlLabel
        label={props.label}
        sx={{
          alignItems: props.alignItems ?? 'flex-start'
        }}
        control={
          <Grid
            container
            width='max-content'
            height='100%'
          >
            <MuiCheckbox
              {...configCheckbox} 
              disabled={props.disabled}
              sx={props.sx?.checkbox}
              {...(
                meta.value != null
                ? { checked: meta.value }
                : {}
              )}
            />
          </Grid>
        }
      />
      {meta.touched && meta.error &&
        <FormHelperText>
          {meta.error}
        </FormHelperText>
      }
    </FormControl>
  )
}

export default Checkbox
