import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Location } from '../../../types/locations'
import Badge from '../../customMui/Badge'

type Props = {
  location: Location
}

export default function TileDetails(props: Props) {
  const texts = useTranslation('locationList').t

  return (
    <Grid
      item
      container
      flexDirection='column'
      justifyContent='space-between'
      paddingX='.75rem'
      sx={{ opacity: props.location.active ? 1 : 0.5}}
    >
      <Grid
        item
        container
        flexDirection='column'
        gap='.5rem'
      >
        <Typography
          variant='body1'
          fontWeight='bold'
        >
          {props.location.name}
        </Typography>
        <Typography variant='body1'>
          {`${props.location.address.streetAddress}, ${props.location.address.postalCode} ${props.location.address.town}`}
        </Typography>
      </Grid>
      <Badge label={texts('room_count_label', { rooms: props.location.trainingRoomsCount })} />
    </Grid>
  )
}