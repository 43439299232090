import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import AuthApi from '../../api/auth/AuthApi'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/errors'
import { queryNames } from '../queries'

const authApi = AuthApi.getInstance()

export function useCheckActivationToken(token: string, enabled: boolean) {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const query = useQuery(
    [queryNames.checkActivationToken, token],
    () => authApi.checkActivationToken({ token: token }),
    {
      onError: (error: AxiosError<HttpError>) => {
        showErrorToast(error, texts)
        navigate('/', { replace: true })
      },
      enabled: enabled,
      retry: false
    })
  return query
}