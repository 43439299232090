import { Typography, Box, Divider } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import TextField from '../../components/customMui/TextField'
import { Form, Formik } from 'formik'
import { emailFormDataValidation } from '../../validations/auth'
import { useRequestPwdReset } from '../../hooks/auth/useRequestPwdReset'
import Link from '../../components/customMui/Link'
import { EmailFormData } from '../../types/forms/auth'
import { mapEmailFormData } from '../../mapping/auth'
import useGaPageView from '../../hooks/google/useGaPageView'

export default function RequestPwdReset() {
  useGaPageView('Forget password')
  const texts = useTranslation(['common', 'requestPwdReset']).t
  const mutation = useRequestPwdReset()

  const handleSubmit = (data: EmailFormData) => {
    mutation.mutate(mapEmailFormData(data))
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      marginTop='5rem'
      width='100%'
    >
      <Typography variant='h1'>
        {texts('common:auth_pages_title')}
      </Typography>
      <Typography 
        variant='subtitle1'
        marginTop='1rem'
      >
        {texts('requestPwdReset:subtitle')}
      </Typography>
      <Formik<EmailFormData>
        initialValues={{ 
          email: ''
        }}
        onSubmit={handleSubmit}
        validateOnBlur
        validationSchema={emailFormDataValidation(texts)}
      >
        {(formikProps) => (
          <Form>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              width='24.75rem'
              sx={{
                marginTop: '2.375rem',
              }}
            >
              <TextField
                name='email'
                label={texts('requestPwdReset:email_label')}
              />

              <LoadingButton
                type='submit'
                variant='contained'
                loading={mutation.isLoading}
                disabled={!formikProps.dirty || !formikProps.isValid}
                sx={{
                  marginTop: '1rem',
                  width: '100%'
                }}
              >
                {texts('common:send')}
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
      <Divider 
        sx={{
          marginTop: '2.5rem', 
          width: '15rem'
        }}
      />
      <Typography 
        variant='body1'
        marginTop='1rem'
      >
        {texts('requestPwdReset:login_link_description')}
        <Link to='/'>
          {texts('requestPwdReset:login')}
        </Link>
      </Typography>
    </Box>
  )
}