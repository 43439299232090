import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
}

export default function useRemoveException() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation((data: MutationParams) => dataApi.removeException(data.uuid), {
    onError: (error: AxiosError<HttpError>) => {
      if (isDataError(error, DataSubcodes.EXCEPTION_NOT_FOUND)) {
        queryClient.resetQueries([queryNames.getExceptionList])
      }
      showErrorToast(error, texts)
    },
    onSuccess: () => {
      queryClient.resetQueries([queryNames.getExceptionList])
      queryClient.resetQueries([queryNames.getEventList])
      toast.success(texts('successes:exception_removed'))
    }
  })

  return mutation
}
