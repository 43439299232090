import { PlaceListDto, PlaceDto, PlaceAddressDto, PlaceBodyDto, PlaceDetailsDto } from '../api/data/types'
import { LocationFormData } from '../types/forms/data'
import { Location, LocationAddress, LocationList, LocationDetails } from '../types/locations'
import { mapPreviewPhotoDto } from './common'
import { mapPhotoDto } from './photo'

export function mapPlaceListDto(list: PlaceListDto): LocationList {
  return {
    totalCount: list.totalCount,
    places: list.places.map(mapPlaceDto)
  }
}

export function mapPlaceDto(place: PlaceDto): Location {
  return {
    uuid: place.uuid,
    name: place.name.trim(),
    active: place.active,
    trainingRoomsCount: place.trainingRoomsCount,
    photo: place.photo ? mapPreviewPhotoDto(place.photo) : null,
    address: mapPlaceAddressDto(place.address),
  }
}

export function mapPlaceAddressDto(address: PlaceAddressDto): LocationAddress {
  return {
    ...address
  }
}

export function mapLocationFormData(form: LocationFormData): PlaceBodyDto {
  return {
    name: form.name.trim(),
    photoUuid: form.photo?.original?.uuid ?? null,
    lockId: form.lockId,
    address: {
      town: form.town,
      streetAddress: form.street,
      postalCode: form.postalCode
    },
    coordinates: [form.coordinates.lng, form.coordinates.lat],
    note: form.directions.trim() ? form.directions : null, // If null or empty string return null
    contact: {
      email: form.contactEmail.trim(),
      phone: form.contactPhone.trim()
    }
  }
}

export function mapPlaceDetailsDto(place: PlaceDetailsDto): LocationDetails {
  return {
    uuid: place.uuid,
    name: place.name.trim(),
    active: place.active,
    photo: place.photo ? mapPhotoDto(place.photo) : null,
    lockId: place.lockId,
    address: {
      town: place.address.town,
      streetAddress: place.address.streetAddress,
      postalCode: place.address.postalCode
    },
    coordinates: {
      lat: place.coordinates[1],
      lng: place.coordinates[0]
    },
    directions: place.note,
    contact: {
      email: place.contact.email,
      phone: place.contact.phone
    }
  }
}

export function mapLocationDetails(location: LocationDetails): LocationFormData {
  return {
    name: location.name.trim(),
    street: location.address.streetAddress,
    postalCode: location.address.postalCode,
    town: location.address.town,
    contactEmail: location.contact.email,
    contactPhone: location.contact.phone,
    directions: location.directions ?? '',
    photo: location.photo,
    coordinates: location.coordinates,
    lockId: location.lockId
  }
}