import { AccountDto, AccountListDto, AccountNameDto, CreateAdminAccountDto } from '../api/data/types'
import { Account, AccountList } from '../types/account'
import { AccountNameFormData, CreateAccountFormData } from '../types/forms/data'

export function mapAccountListDto(data: AccountListDto): AccountList {
  return {
    totalCount: data.totalCount,
    accounts: data.accounts.map(mapAccountDto)
  }
}

export function mapAccountDto(account: AccountDto): Account {
  return {
    ...account,
  }
}

export function mapAccountNameFormData(data: AccountNameFormData): AccountNameDto {
  return {
    name: data.name.trim()
  }
}

export function mapCreateAccountFormData(account: CreateAccountFormData): CreateAdminAccountDto {
  return {
    name: account.name.trim(),
    email: account.email.trim()
  }
}