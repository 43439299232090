import { Success } from '../../types/common'
import { Api } from '../Api'
import { AccountDto, AccountListDto, AccountListOptionsDto, AccountNameDto, AccountResponseDto, CreateAdminAccountDto, CreateExceptionDto, EditExceptionDto, EditTrainingRoomDto, EventDto, EventListOptionsDto, EventPatternBodyDto, EventPatternDto, EventPatternListDto, EventPatternListOptionsDto, ExceptionDto, ExceptionListDto, ExceptionListOptionsDto, PlaceBodyDto, PlaceDetailsDto, PlaceListDto, SearchListOptionsDto, SelectedPlaceDto, TrainingRoomDto, EquipmentDto, TrainingRoomListDto, UploadedPhotoDto, CreateTrainingRoomDto, TrainingRoomDetailsDto, TrainerListDto, TrainerListOptionsDto, TrainerItemDto, TrainerDto, ReservationOwnerDto } from './types'
import { mapTrainerListOrder } from './mapping'
import { AxiosResponse } from 'axios'

export default class DataApi extends Api {
  private constructor() {
    super({
      baseURL: `${process.env.REACT_APP_BASE_DATA}/api/v1/admin/`
    })
  }
  private static instance: DataApi

  static getInstance(): DataApi {
    if (DataApi.instance) {
      return this.instance
    }
    return this.instance = new DataApi()
  }

  public createAdminAccount(data: CreateAdminAccountDto): Promise<AxiosResponse<AccountResponseDto>> {
    return this.post<AccountResponseDto>('account/create', data)
  }

  public getPlaceList(options: SearchListOptionsDto): Promise<AxiosResponse<PlaceListDto>> {
    return this.get<PlaceListDto>('place/list', {
      params: {
        ...(options.limit != null ? { limit: options.limit } : {}),
        ...(options.offset != null ? { offset: options.offset } : {}),
        ...(options.search ? { search: options.search } : {})
      }
    })
  }

  public getPlaceTrainingRoomList(uuid: string, options: SearchListOptionsDto): Promise<AxiosResponse<TrainingRoomListDto>> {
    return this.get<TrainingRoomListDto>(`place/${uuid}/training-room/list`, {
      params: {
        ...(options.limit != null ? { limit: options.limit } : {}),
        ...(options.offset != null ? { offset: options.offset } : {}),
        ...(options.search ? { search: options.search } : {})
      }
    })
  }
  
  public getAccountList(options: AccountListOptionsDto): Promise<AxiosResponse<AccountListDto>> {
    return this.get<AccountListDto>('account/list', {
      params: {
        ...(options.order != null ? { order: options.order } : {}),
        ...(options.limit != null ? { limit: options.limit } : {}),
        ...(options.offset != null ? { offset: options.offset } : {}),
        ...(options.status != null ? { status: options.status } : {}),
        ...(options.search ? { search: options.search } : {})
      }
    })
  }
  
  public getTrainerList(options: TrainerListOptionsDto): Promise<AxiosResponse<TrainerListDto>> {
    return this.get<TrainerListDto>('trainer/list', {
      params: {
        from: options.from,
        to: options.to,
        ...(options.order != null ? { order: mapTrainerListOrder(options.order) } : {}),
        ...(options.limit != null ? { limit: options.limit } : {}),
        ...(options.offset != null ? { offset: options.offset } : {}),
        ...(options.search ? { search: options.search } : {})
      }
    })
  }

  public searchLocations(search: string, active?: boolean): Promise<AxiosResponse<SelectedPlaceDto[]>> {
    return this.get<SelectedPlaceDto[]>('place/search', {
      params: {
        search: search,
        active: active
      }
    })
  }

  public getSelectedPlacesWithRooms(uuids: string[], active?: boolean): Promise<AxiosResponse<SelectedPlaceDto[]>> {
    return this.get<SelectedPlaceDto[]>('place/select', {
      params: {
        uuids: uuids,
        active: active
      }
    })
  }

  public getTrainingRoomDetails(uuid: string): Promise<AxiosResponse<TrainingRoomDetailsDto>> {
    return this.get<TrainingRoomDetailsDto>(`training-room/${uuid}/details`)
  }

  public getPlaceDetails(uuid: string): Promise<AxiosResponse<PlaceDetailsDto>> {
    return this.get<PlaceDetailsDto>(`place/${uuid}/details`)
  }

  public getEquipmentList(): Promise<AxiosResponse<EquipmentDto[]>> {
    return this.get<EquipmentDto[]>('equipment/list')
  }

  public getTrainerDetails(uuid: string): Promise<AxiosResponse<TrainerDto>> {
    return this.get<TrainerDto>(`trainer/${uuid}/details`)
  }
  
  public changePlaceActiveState(uuid: string, active: boolean): Promise<AxiosResponse<Success>> {
    return this.patch<Success>(`place/${uuid}/change/active`, { active: active })
  }

  public changeRoomActiveState(uuid: string, active: boolean): Promise<AxiosResponse<Success>> {
    return this.patch<Success>(`training-room/${uuid}/change/active`, { active: active })
  }

  public createPlace(data: PlaceBodyDto): Promise<AxiosResponse<Success<PlaceDetailsDto>>> {
    return this.post<Success<PlaceDetailsDto>>('place/create', data)
  }

  public editPlace(uuid: string, data: PlaceBodyDto): Promise<AxiosResponse<Success<PlaceDetailsDto>>> {
    return this.patch<Success<PlaceDetailsDto>>(`place/${uuid}/edit`, data)
  }

  public uploadPhoto(data: FormData, signal: AbortSignal): Promise<AxiosResponse<UploadedPhotoDto>> {
    return this.post<UploadedPhotoDto>(`photo/upload`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      signal: signal
    })
  }

  public createTrainingRoom(data: CreateTrainingRoomDto): Promise<AxiosResponse<Success<TrainingRoomDto>>> {
    return this.post<Success<TrainingRoomDto>>('training-room/create', data)
  }

  public editTrainingRoom(roomUuid: string, data: EditTrainingRoomDto): Promise<AxiosResponse<Success<TrainingRoomDto>>> {
    return this.patch<Success<TrainingRoomDto>>(`training-room/${roomUuid}/edit`, data)
  }

  public changeAccountActiveState(uuid: string, active: boolean): Promise<AxiosResponse<Success<AccountDto>>> {
    return this.patch<Success<AccountDto>>(`account/${uuid}/change/active`, { active: active })
  }

  public changeAccountNameState(uuid: string, data: AccountNameDto): Promise<AxiosResponse<Success>> {
    return this.patch<Success>(`account/${uuid}/change/name`, data)
  }

  public getEventPatternList(options: EventPatternListOptionsDto): Promise<AxiosResponse<EventPatternListDto>> {
    return this.get<EventPatternListDto>('event-pattern/list', {
      params: {
        timezone: options.timezone,
        ...(options.limit != null ? { limit: options.limit } : {}),
        ...(options.offset != null ? { offset: options.offset } : {}),
      }
    })
  }

  public getExceptionList(options: ExceptionListOptionsDto): Promise<AxiosResponse<ExceptionListDto>> {
    return this.get<ExceptionListDto>('exception/list', {
      params: {
        timezone: options.timezone,
        ...(options.limit != null ? { limit: options.limit } : {}),
        ...(options.offset != null ? { offset: options.offset } : {})
      }
    })
  }

  public getEventPatternDetails(uuid: string): Promise<AxiosResponse<EventPatternDto>> {
    return this.get<EventPatternDto>(`event-pattern/${uuid}/details`)
  }

  public getExceptionDetails(uuid: string): Promise<AxiosResponse<ExceptionDto>> {
    return this.get<ExceptionDto>(`exception/${uuid}/details`)
  }

  public createEventPattern(data: EventPatternBodyDto): Promise<AxiosResponse<Success<EventPatternDto>>> {
    return this.post<Success<EventPatternDto>>('event-pattern/create', data)
  }

  public editEventPattern(uuid: string, data: EventPatternBodyDto): Promise<AxiosResponse<Success<EventPatternDto>>> {
    return this.patch<Success<EventPatternDto>>(`event-pattern/${uuid}/edit`, data)
  }

  public removeEventPattern(uuid: string): Promise<AxiosResponse<Success>> {
    return this.delete<Success>(`event-pattern/${uuid}/delete`)
  }

  public createException(data: CreateExceptionDto): Promise<AxiosResponse<Success<ExceptionDto>>> {
    return this.post<Success<ExceptionDto>>('exception/create', data)
  }

  public editException(uuid: string, data: EditExceptionDto): Promise<AxiosResponse<Success<ExceptionDto>>> {
    return this.patch<Success<ExceptionDto>>(`exception/${uuid}/edit`, data)
  }

  public removeException(uuid: string): Promise<AxiosResponse<Success>> {
    return this.delete<Success>(`exception/${uuid}/delete`)
  }

  public getEventList(options: EventListOptionsDto): Promise<AxiosResponse<EventDto[]>> {
    return this.get<EventDto[]>('event/list', {
      params: {
        from: options.from,
        to: options.to,
        ...(options.places != null ? { places: options.places } : {}),
        ...(options.rooms != null ? { rooms: options.rooms } : {}),
        ...(options.timesofday != null ? { timesofday: options.timesofday } : {}),
        ...(options.activeplaces != null ? { activeplaces: options.activeplaces } : {})
      }
    })
  }

  public removeEvent(uuid: string): Promise<AxiosResponse<Success>> {
    return this.delete<Success>(`event/${uuid}/delete`)
  }

  public changeTrainerActiveState(uuid: string, active: boolean): Promise<AxiosResponse<Success<Omit<TrainerItemDto, 'traineesCount' | 'entriesCount'>>>> {
    return this.patch<Success<Omit<TrainerItemDto, 'traineesCount' | 'entriesCount'>>>(`trainer/${uuid}/change/active`, { active: active })
  }

  public getEventReservations(uuid: string): Promise<AxiosResponse<ReservationOwnerDto[]>> {
    return this.get<ReservationOwnerDto[]>(`event/${uuid}/reservations/owner/list`, {
      params: {
        limit: 100,
        offset: 0
      }
    })
  }
}