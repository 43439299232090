import { Grid } from '@mui/material'
import TopSection from '../../../components/exceptions/list/TopSection'
import MainSection from '../../../components/exceptions/list/MainSection'
import useGaPageView from '../../../hooks/google/useGaPageView'

export default function ExceptionList() {
  useGaPageView('Exceptions list')

  return (
    <Grid width='100%' paddingX='.5rem'>
      <TopSection />
      <MainSection />
    </Grid>
  )
}