import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { EventPatternBodyDto } from '../../../api/data/types'
import { mapEventPatternDto } from '../../../mapping/eventPatterns'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import { HttpError } from '../../../types/common'
import { EventPattern, EventPatternList } from '../../../types/eventPatterns'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  data: EventPatternBodyDto
}

export default function useEditEventPattern(uuid: string) {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation(async (params: MutationParams) => {
    const { data } = await dataApi.editEventPattern(uuid, params.data)
    return mapEventPatternDto(data.data!)
  }, {
    onError: (error: AxiosError<HttpError>) => {
      if (isDataError(error, [DataSubcodes.PLACE_NOT_FOUND, DataSubcodes.PLACE_REMOVED])) {
        queryClient.resetQueries([queryNames.getLocationList])
        queryClient.resetQueries([queryNames.searchLocations])
      }
      if (isDataError(error, [DataSubcodes.EVENT_PATTERN_NOT_FOUND, DataSubcodes.EVENT_PATTERN_REMOVED])) {
        queryClient.resetQueries([queryNames.getEventPatternList])
        queryClient.removeQueries([queryNames.getEventPatternDetails, uuid])
      }
      showErrorToast(error, texts)
    },
    onSuccess: (data: EventPattern, variables: MutationParams) => {
      const queriesData = queryClient.getQueriesData<EventPatternList>([queryNames.getEventPatternList])
      for (const queryData of queriesData) {
        queryClient.setQueryData(queryData[0], (oldData?: EventPatternList) => {
          if (!oldData) {
            return oldData
          }
          const newData = [...oldData.eventPatterns]
          const index = newData.findIndex(p => p.uuid === uuid)
          newData[index] = data
          return {
            totalCount: oldData.totalCount,
            eventPatterns: newData
          }
        })
      }
      queryClient.resetQueries([queryNames.getEventList])
      toast.success(texts('successes:event_pattern_updated'))
    }
  })

  return mutation
}
