import { Trans, useTranslation } from 'react-i18next'
import Dialog from '../customMui/Dialog'
import useChangeAccountActiveState from '../../hooks/data/accounts/useChangeAccountActiveState'
import { Account } from '../../types/account'
import { AccountStatus } from '../../types/common'

type Props = {
  open: boolean
  account: Account | null
  onClose: () => void
}

export default function UpdateAccountActiveStateDialog(props: Props) {
  const texts = useTranslation('dialogs').t
  const mutation = useChangeAccountActiveState()

  return (
    <Dialog
      open={props.open}
      title={<Trans i18nKey={props.account?.status !== AccountStatus.INACTIVE ? 'dialogs:deactivate_account_title' : 'dialogs:activate_account_title'}/>}
      description={<Trans i18nKey={props.account?.status !== AccountStatus.INACTIVE ? 'dialogs:deactivate_account_description' : 'dialogs:activate_account_description'}/>}
      warning={<Trans i18nKey={props.account?.status !== AccountStatus.INACTIVE ? 'dialogs:deactivate_account_warning' : 'dialogs:activate_account_warning'}/>}
      closeButtonLabel={texts(props.account?.status !== AccountStatus.INACTIVE ? 'deactivate_account_cancel' : 'activate_account_cancel')}
      confirmButtonLabel={texts(props.account?.status !== AccountStatus.INACTIVE ? 'deactivate_account_confirm' : 'activate_account_confirm')}
      loading={mutation.isLoading}
      onCloseButtonClick={props.onClose}
      onConfirmButtonClick={() => {
        if (props.account != null) {
          mutation.mutate({
            uuid: props.account.uuid,
            active: !(props.account.status !== AccountStatus.INACTIVE)
          }, {
            onSettled: props.onClose
          })
        }
      }}
    />
  )
}
