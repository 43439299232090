import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { EventListOptionsDto } from '../../../api/data/types'
import { mapEventDtoList } from '../../../mapping/schedule'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled: boolean
}

export default function useGetEventList(options: EventListOptionsDto & Options) {
  const texts = useTranslation().t

  const query = useQuery([queryNames.getEventList, options.from, options.to, options.places, options.rooms, options.timesofday, options.activeplaces], async () => {
    const { data } = await dataApi.getEventList(options)
    return mapEventDtoList(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}
