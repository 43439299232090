import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Room } from '../../../types/rooms'
import Badge from '../../customMui/Badge'

type Props = {
  room: Room
}

export default function TileDetails(props: Props) {
  const texts = useTranslation('roomList').t
  
  return (
    <Grid 
      container
      alignItems='center'
      gap='.625rem'
      sx={{
        opacity: props.room.active ? 1 : 0.5
      }}
    >
      <Box
        sx={{
          width: '2rem',
          height: '2rem',
          backgroundColor: props.room.color
        }}
      />
      <Typography
        variant='body1'
        fontWeight={700}
      >
        {props.room.name}
      </Typography>
      <Badge label={texts('spot_count_label', { count: props.room.seats })} />              
    </Grid>
  )
}