import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { HttpError, SelectedLocation } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'
import { mapSelectedPlaceDtoList } from '../../../mapping/common'

const dataApi = DataApi.getInstance()

export default function useGetSelectedPlacesWithRooms(
  uuids: string[],
  onSuccess: (data: SelectedLocation[]) => void,
  enabled: boolean,
  active?: boolean
) {
  const texts = useTranslation().t

  const query = useQuery([queryNames.getSelectedPlacesWithRooms, uuids.join(','), active], async () => {
    const { data } = await dataApi.getSelectedPlacesWithRooms(uuids, active)
    return mapSelectedPlaceDtoList(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: enabled,
    onSuccess: onSuccess,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}
