import { useTranslation } from 'react-i18next'
import ErrorPage from './ErrorPage'
import { InternetErrorBase64Src } from '../../utils/const'
import useGaPageView from '../../hooks/google/useGaPageView'

type Props = {
  onRefresh: () => void
}

export default function ConnectionError(props: Props) {
  useGaPageView('Refresh error')
  const texts = useTranslation('errors').t

  return (
    <ErrorPage
      image={InternetErrorBase64Src}
      title={texts('connection_error_title')}
      description={texts('connection_error_subtitle')}
      button={{
        label: texts('connection_error_button_label'),
        onClick: props.onRefresh
      }}
      sx={{
        img: {
          marginBottom: '2rem',
          width: '29.125rem',
          height: '20rem'
        }
      }}
    />
  )
}
