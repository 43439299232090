import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function TileInactiveBadge() {
  const texts = useTranslation('common').t

  return (
    <Box
      sx={{
        borderTop: '0rem solid transparent',
        borderRight: '0rem solid transparent',
        borderLeft: '5rem solid rgba(92,15,139, 1)',
        borderBottom: '5rem solid transparent',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2
      }}
    >
      <Typography
        variant='badge'
        sx={{
          position: 'absolute',
          top: '1.5625rem',
          left: '-5rem',
          color: 'white',
          transform: 'rotate(-45deg)'
        }}
      >
        {texts('inactive')}
      </Typography>
    </Box>
  )
}