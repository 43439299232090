import { Card, CardActions, CardContent, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Exception } from '../../../types/exceptions'
import moment from 'moment'
import TileMenuSection from './TileMenuSection'
import { useState } from 'react'
import RemoveExceptionDialog from '../../dialogs/RemoveExceptionDialog'

type Props = {
  exception: Exception
}

export default function Tile(props: Props) {
  const texts = useTranslation('exceptionList').t
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  return (
    <>
      <RemoveExceptionDialog 
        open={isDialogOpen}
        exception={props.exception}
        onClose={() => { setIsDialogOpen(false) }}
      />
      <Card 
        sx={{
          display: 'flex',
          position: 'relative'
        }}
      >
        <CardContent sx={{ flexGrow: 1 }}>
          <Grid
            container
            flexDirection='column'
            gap='.5rem'
          >
            <Typography
              variant='body1'
              fontWeight={700}
            >
              {moment(props.exception.scheduledAt, 'YYYY-MM-DD').format('DD.MM.YYYY')}
              {
                props.exception.unavailableFrom && props.exception.unavailableTo &&
                  texts(
                    'tile_time_range',
                    {
                      start: props.exception.unavailableFrom,
                      end: props.exception.unavailableTo
                    }
                  )
              }
            </Typography>
            <Typography variant='body1'>
              {
                texts('tile_place_row', { 
                  place: props.exception.place.name,
                  rooms: props.exception.place.trainingRooms.map(r => r.name).join(', ')
                })
              }
            </Typography>
          </Grid>
        </CardContent>
        <CardActions 
          sx={{ 
            flexDirection: 'column',
            justifyContent: 'flex-start'
          }}
        >
          <TileMenuSection 
            exception={props.exception}
            onRemoveClick={() => { setIsDialogOpen(true) }}
          />
        </CardActions>
      </Card>
    </>
  )
}