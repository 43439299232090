import { TextField as MuiTextField, TextFieldProps, InputAdornment, IconButton, SxProps } from '@mui/material'
import { useField } from 'formik'
import { Visibility } from '@mui/icons-material'
import * as React from 'react'
import VisibilityOffIcon from '../icons/VisibilityOffIcon'

type Props = {
  name: string
  label?: string
  disabled?: boolean
  placeholder?: string
  sx?: SxProps
  size?: 'small' | 'medium'
  InputProps?: {
    endAdornment?: React.ReactNode
    startAdornment?: React.ReactNode
  }
}

export default function PasswordField(props: Props) {
  const [field, meta] = useField(props.name)
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  const configTextField: TextFieldProps = {
    ...field,
    ...props,
    fullWidth: true,
    variant: 'outlined'
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return (
    <MuiTextField
      {...configTextField}
      size={props.size}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: 
          <InputAdornment position='end'>
            <IconButton
              style={{ color: 'black' }}
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword 
                ? <VisibilityOffIcon 
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem'
                    }}
                  />
                : <Visibility/>
              }
            </IconButton>
          </InputAdornment>
      }}
    /> 
  )
}