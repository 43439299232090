import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react'
import { useMutation } from '@tanstack/react-query'
import DataApi from '../../../api/data/DataApi'
import { showErrorToast } from '../../../utils/errors'
import { mapUploadedPhotoResponseDto } from '../../../mapping/photo'

const dataApi = DataApi.getInstance()

type MutationParams = {
  file: File
  thumbnailWidth: number
}

export default function useUploadPhoto(abort: boolean) {
  const texts = useTranslation().t
  const abortControllerRef = useRef<AbortController | null>(null)

  useEffect(() => {
    if (abort) {
      abortControllerRef.current?.abort()
    }
  }, [abort])

  const mutation = useMutation(async (params: MutationParams) => {
      abortControllerRef.current = new AbortController()
      const formData = new FormData()
      formData.append('file', params.file)
      formData.append('data', JSON.stringify({
        thumbnailWidth: params.thumbnailWidth
      }))
      const { data } = await dataApi.uploadPhoto(formData, abortControllerRef.current.signal)
      return mapUploadedPhotoResponseDto(data)
    }, {
    onError: (error: any) => {
      if (error.name !== 'CanceledError') {
        showErrorToast(error, texts)
      }
    },
  })
  return mutation
}