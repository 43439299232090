import { DataGridPro, DataGridProProps, GridLinkOperator } from "@mui/x-data-grid-pro"
import { JSXElementConstructor } from 'react'

type Props = {
  noRowsOverlay?: JSXElementConstructor<any>
  rowHeight: number
} & Omit<DataGridProProps, 'disableSelectionOnClick' | 'components'>

export default function DataGrid(props: Props) {
  return (
    <DataGridPro
      components={{
        NoRowsOverlay: props.noRowsOverlay
      }}
      componentsProps={{
        baseSelect: {
          native: false
        },
        panel: {
          placement: 'bottom-end'
        },
        filterPanel: {
          linkOperators: [GridLinkOperator.And],
          filterFormProps: {
            linkOperatorInputProps: {
              variant: 'outlined',
              size: 'small',
            },
            columnInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' },
            },
            operatorInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' },
            },
            valueInputProps: {
              InputComponentProps: {
                variant: 'outlined',
                size: 'small'
              },
            }
          }
        }
      }}
      paginationMode='server'
      sortingMode='server'
      filterMode='server'
      pagination
      disableColumnSelector 
      disableColumnPinning
      disableSelectionOnClick
      disableMultipleColumnsFiltering
      {...props}
      autoHeight
    />
  )
}