import { LoadingButton } from '@mui/lab'
import { Box, Divider, Typography } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import Loader from '../../components/common/Loader'
import Link from '../../components/customMui/Link'
import PasswordField from '../../components/customMui/PasswordField'
import { useCheckPwdResetToken } from '../../hooks/auth/useCheckPwdResetToken'
import { useFinishPwdReset } from '../../hooks/auth/useFinishPwdReset'
import { PasswordFormData } from '../../types/forms/auth'
import useGaPageView from '../../hooks/google/useGaPageView'
import { passwordFormDataValidation } from '../../validations/auth'

export default function FinishPwdReset() {
  useGaPageView('Set new password')
  const texts = useTranslation(['finishPwdReset', 'common']).t
  const navigate = useNavigate()
  const [token] = useQueryParam('token', withDefault(StringParam, ''))
  const checkToken = useCheckPwdResetToken(token, Boolean(token))
  const activateMutation = useFinishPwdReset()

  useEffect(() => {
    if (!token) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (data: PasswordFormData, formikHelpers: FormikHelpers<PasswordFormData>) => {
    activateMutation.mutate({
      password: data.password,
      token: token!
    }, {
      onError: () => {
        formikHelpers.setFieldValue('password', '')
      }
    })
  }

  return (
    <>
      {
        checkToken.isLoading
        ? <Loader />
        : <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            marginTop='5rem'
            width='100%'
          >
            <Typography variant='h1'>
              {texts('common:auth_pages_title')}
            </Typography>
            <Typography 
              variant='subtitle1'
              marginTop='1rem'
            >
              {texts('finishPwdReset:subtitle')}
            </Typography>
            <Formik<PasswordFormData>
              initialValues={{ 
                password: ''
              }}
              onSubmit={handleSubmit}
              validateOnBlur
              validationSchema={passwordFormDataValidation(texts)}
            >
              {(formikProps) => (
                <Form>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    width='24.75rem'
                    sx={{
                      marginTop: '2.375rem',
                    }}
                  >
                    <PasswordField
                      name='password'
                      label={texts('finishPwdReset:password_label')}
                    />

                    <LoadingButton
                      type='submit'
                      variant='contained'
                      loading={activateMutation.isLoading}
                      disabled={!formikProps.dirty || !formikProps.isValid}
                      sx={{
                        marginTop: '1rem',
                        width: '100%'
                      }}
                    >
                      {texts('common:save')}
                    </LoadingButton>
                  </Box>
                </Form>
              )}
            </Formik>
            <Divider 
              sx={{
                marginTop: '2.5rem', 
                width: '15rem'
              }}
            />
            <Typography 
              variant='body1'
              marginTop='1rem'
            >
              {texts('finishPwdReset:login_link_description')}
              <Link to='/'>
                {texts('finishPwdReset:login')}
              </Link>
            </Typography>
          </Box>
      }
    </>
  )
}