import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { CreateTrainingRoomDto, EquipmentDto } from '../../../api/data/types'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  data: CreateTrainingRoomDto
}

export default function useCreateRoom() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation((data: MutationParams) => dataApi.createTrainingRoom(data.data), {
    onError: (error: AxiosError<HttpError>) => {
      if (isDataError(error, DataSubcodes.EQUIPMENT_ITEM_NOT_FOUND)) {
        const queryData = queryClient.getQueryData<EquipmentDto[]>([queryNames.getEquipmentList])
        const newData = queryData?.filter(item => !error.response!.data.data?.uuids.includes(item.uuid))
        queryClient.setQueryData([queryNames.getEquipmentList], newData)
      }
      if (isDataError(error, [DataSubcodes.PLACE_NOT_FOUND, DataSubcodes.PLACE_REMOVED])) {
        queryClient.resetQueries([queryNames.getPlaceList])
      }
      showErrorToast(error, texts)
    },
    onSuccess: (_, variables: MutationParams) => {
      queryClient.resetQueries([queryNames.getPlaceList])
      queryClient.resetQueries([queryNames.getRoomList, variables.data.placeUuid])
      toast.success(texts('successes:room_created'))
    }
  })

  return mutation
}
