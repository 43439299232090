import { AxiosError } from "axios"
import { HttpError } from "../../types/common"
import { Codes } from "./consts"


export function isDataError(error: AxiosError<HttpError>, subcode: number): boolean
export function isDataError(error: AxiosError<HttpError>, subcodes: number[]): boolean

export function isDataError(error: AxiosError<HttpError>, subcode: unknown): boolean {
  if (error.response == null || error.response.data.code !== Codes.DATA) {
    return false
  }
  if (typeof subcode === 'number') {
    return error.response.data.subcode === subcode
  } else if (Array.isArray(subcode)) {
    return subcode.includes(error.response.data.subcode)
  }

  throw Error(`Invalid subcode type: ${typeof subcode}`)
}

export function isAuthError(error: AxiosError<HttpError>, subcode: number): boolean
export function isAuthError(error: AxiosError<HttpError>, subcodes: number[]): boolean

export function isAuthError(error: AxiosError<HttpError>, subcode: unknown): boolean {
  if (error.response == null || error.response.data.code !== Codes.AUTH) {
    return false
  }
  if (typeof subcode === 'number') {
    return error.response.data.subcode === subcode
  } else if (Array.isArray(subcode)) {
    return subcode.includes(error.response.data.subcode)
  }

  throw Error(`Invalid subcode type: ${typeof subcode}`)
}