import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import Loader from '../../../components/common/Loader'
import MainSection from '../../../components/exceptions/page/MainSection'
import TopSection from '../../../components/exceptions/page/TopSection'
import useGetExceptionDetails from '../../../hooks/data/exceptions/useGetExceptionDetails'
import useGetSelectedPlacesWithRooms from '../../../hooks/data/locations/useGetSelectedPlacesWithRooms'
import useGaPageView from '../../../hooks/google/useGaPageView'
import { queryNames } from '../../../hooks/queries'
import { mapException } from '../../../mapping/exceptions'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import ConnectionError from '../../Errors/ConnectionError'

export default function ExceptionPage() {
  const texts = useTranslation('errors').t
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { uuid } = useParams<{ uuid?: string }>()

  const isEditPage = Boolean(uuid)

  useGaPageView(isEditPage ? 'Edit exception' : 'Add exception')

  const exceptionDetails = useGetExceptionDetails(
    uuid ?? '',
    (error) => {
      if (isDataError(error, DataSubcodes.EXCEPTION_NOT_FOUND)) {
        navigate(-1)
      }
    },
    isEditPage
  )

  const selectedPlace = useGetSelectedPlacesWithRooms(
    [exceptionDetails.data?.place.uuid ?? ''],
    (data) => {
      if (data.length === 0) {
        toast.error(texts('place_not_found'))
      }
    },
    exceptionDetails.data?.place.uuid != null && isEditPage
  )

  if (exceptionDetails.isFetching || selectedPlace.isFetching) {
    return <Loader />
  }

  if (exceptionDetails.isError || selectedPlace.isError) {
    return (
      <ConnectionError 
        onRefresh={() => {
          if (exceptionDetails.isError) {
            return queryClient.resetQueries([queryNames.getEventPatternDetails, uuid])
          }
          if (selectedPlace.isError) {
            queryClient.resetQueries([queryNames.getSelectedPlacesWithRooms])
          }
        }}
      />
    )
  }

  return (
    <Grid 
      width='100%' 
      paddingX='.5rem'
      paddingBottom='1rem'
    >
      <TopSection isEditPage={isEditPage}/>
      <MainSection 
        isEditPage={isEditPage}
        initialValues={
          isEditPage
          ? mapException(exceptionDetails.data!, selectedPlace.data![0]?.trainingRooms ?? [])
          : undefined
        }
      />
    </Grid>
  )
}