import { Box, Button, SxProps, Typography, Grid } from '@mui/material'

type Props = {
  image: string
  title: string
  description: string
  button?: {
    label: string
    onClick: () => void
  }
  sx?: {
    container?: SxProps
    img?: SxProps
    title?: SxProps
    description?: SxProps
    button?: SxProps
  }
}

export default function ErrorPage(props: Props) {
  return (
    <Grid
      container
      alignItems='center'
      flexDirection='column'
      justifyContent='center'
      sx={props.sx?.container ?? {
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <Box
        component='img'
        src={props.image}
        sx={props.sx?.img ?? {
          marginBottom: '2rem',
          width: '15.625rem',
          height: '15rem'
        }}
      >
      </Box>
      <Typography
        variant='h3'
        sx={props.sx?.title ?? {
          marginBottom: '1.5rem'
        }}
      >
        {props.title}
      </Typography>
      <Typography
        sx={props.sx?.description ?? {
          marginBottom: '2rem',
          textAlign: 'center'
        }}
      >
        {props.description}
      </Typography>
      {
        props.button && 
          <Button
            disableElevation
            variant='contained'
            onClick={props.button!.onClick}
            sx={props.sx?.button ?? {
              width: '17.5rem',
            }}
          >
            {props.button.label}
          </Button>
      }
    </Grid>
  )
}
