import { ArrowDropDown } from '@mui/icons-material'
import { Button, Grid, Menu, MenuItem, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PatternPageReferrer } from '../../types/common'

export default function TopSection() {
  const texts = useTranslation('schedule').t
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        marginTop='2rem'
      >
        <Typography variant='h2'>
          {texts('title')}
        </Typography>
        <Button
          variant='outlined'
          onClick={handleClick}
          endIcon={<ArrowDropDown />}
          sx={{
            width: '17.5rem'
          }}
        >
          {texts('dropdown_menu_button_label')}
        </Button>
      </Grid>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width: '17.5rem',
          },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          handleClose()
          navigate('/schedule/event-patterns/create', { state: { referrer: PatternPageReferrer.SCHEDULE } })
        }}>
          {texts('dropdown_menu_add_new_event_pattern_label')}
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose()
          navigate('/schedule/event-patterns')
        }}>
          {texts('dropdown_menu_event_pattern_list_label')}
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose()
          navigate('/schedule/exceptions')
        }}>
          {texts('dropdown_menu_exception_list_label')}
        </MenuItem>
      </Menu>
    </>
    
    
  )
}