import { Namespace, TFunction } from 'i18next'
import * as Yup from 'yup'
import { emailRegex } from './regexp'

export function accountNameFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    name: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .max(64, t('validations:max_64_chars'))
  })
}

export function createAccountFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    name: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .max(64, t('validations:max_64_chars')),
    email: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .matches(emailRegex, t('validations:email'))
  })
}