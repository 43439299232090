import { Grid, Typography } from '@mui/material'

type Props = {
  label: string
}

export default function EmptyView(props: Props) {
  return (
    <Grid 
      container
      justifyContent='center'
      alignItems='center'
      height='100%'
    >
      <Typography variant='inputHint'>
        {props.label}
      </Typography>
    </Grid>
  )
}