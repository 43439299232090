import { QueryParamConfig } from "use-query-params"

export enum TrainerStatus {
  INACTIVE,
  WAITING_FOR_VERIFICATION,
  REGISTERED,
  PENDING,
  BILLABLE
}

export type TrainerList = {
  totalCount: number
  trainers: TrainerItem[]
}

export type TrainerItem = {
  uuid: string
  firstName: string
  lastName: string
  createdAt: string
  lastActivity: string | null
  traineesCount: number
  entriesCount: number
  stripeId: string
}

export type TrainerListSearchParams = {
  page: QueryParamConfig<number | null | undefined, number>
  size: QueryParamConfig<number | null | undefined, number>
  order: QueryParamConfig<string | null | undefined, string>
  status: QueryParamConfig<number | null | undefined, number | undefined>
  search: QueryParamConfig<string | null | undefined, string | undefined>
  entriesFrom: QueryParamConfig<string | null | undefined, string>
  entriesTo: QueryParamConfig<string | null | undefined, string>
}

export type Trainer = {
  uuid: string
  firstName: string
  lastName: string
  nick: string | null
  contact: ContactDetails
  stripeId: string
}

export type ContactDetails = {
  email: string
  phone: string | null
}

export type Company = {
  name: string
  taxId: string
  address: CompanyAddress
}

export type CompanyAddress = {
  streetAddress: string
  postalCode: string
  town: string
}