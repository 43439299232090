import { AppBar as MuiAppBar, Box, Typography, Grid } from '@mui/material'
import LogoKolektywBlackImage from '../../assets/images/logoKolektywBlack.svg'
import { useTranslation } from 'react-i18next'
import AccountNav from '../common/AccountNav'

type Props = {
  loggedIn?: boolean
  userName: string
}

export default function AppBar(props: Props) {
  const texts = useTranslation(['common', 'imgAlts']).t

  return (
    <MuiAppBar>
      <Grid
        container
        alignItems='center'
        gap='0.75rem'
      >
        <Box
          marginTop='0.1875rem'
          component='img'
          src={LogoKolektywBlackImage}
          height='1.5rem'
          width='8.75rem'
          alt={texts('imgAlts:main_logo')}
        />
        <Typography
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='5rem'
          height='1.25rem'
          bgcolor='#FFD100'
          borderRadius='0.25rem'
          variant='badge'
        >
          {texts('common:admin_tag')}
        </Typography>
        <Grid 
          item
          flexGrow={1}
        />
        {props.loggedIn && <AccountNav userName={props.userName} />}
      </Grid>
    </MuiAppBar>
  )
}