import { KeyboardBackspace } from '@mui/icons-material'
import { Button, Grid, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { queryNames } from '../../../hooks/queries'
import { RoomListSearchParams } from '../../../types/rooms'
import SearchInput from '../../customMui/SearchInput'

type Props = {
  name: string
}

export default function TopSection(props: Props) {
  const texts = useTranslation('roomList').t
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { uuid } = useParams<{ uuid?: string }>()

  const [searchParams, setSearchParams] = useQueryParams<RoomListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    search: withDefault(StringParam, undefined)
  })

  return (
    <Grid marginY='2rem'>
      <Button 
        variant='text'
        onClick={() => { navigate('/locations', { replace: true })}}
        sx={{ marginBottom: '1rem' }}
      >
        <Grid
          container
          alignItems='center'
          gap='.5rem'
        >
          <KeyboardBackspace sx={{ marginTop: '-.125rem' }} />
          {texts('back_to_location_list')}
        </Grid>
      </Button>
      <Grid
        container
        justifyContent='space-between'
        marginBottom='.25rem'
      >
        <Typography variant='h2'>
          {texts('title')}
        </Typography>
        <Button
          variant='contained'
          onClick={() => {
            navigate(`/locations/${uuid}/rooms/create`)
          }}
          sx={{
            width: '17.5rem'
          }}
        >
          {texts('add_location_button_label')}
        </Button>
      </Grid>
      <Grid marginBottom='1.5rem'>
        <Typography variant='subtitle1'>
          {props.name}
        </Typography>
      </Grid>
      <Grid sx={{ maxWidth: '40%' }}>
        <SearchInput
          placeholder={texts('search_input_placeholder')}
          initValue={searchParams.search ? decodeURI(searchParams.search) : undefined}
          onSearch={(search: string) => {
            queryClient.resetQueries([queryNames.getRoomList, uuid, search ? encodeURI(search) : undefined])
            setSearchParams({ search: search ? encodeURI(search) : undefined, page: 1 }, 'replaceIn')
          }}
          onClear={() => {
            queryClient.resetQueries([queryNames.getRoomList, uuid, undefined])
            setSearchParams({ search: undefined, page: 1 }, 'replaceIn')
          }}
        />
      </Grid>
    </Grid>
  )
}