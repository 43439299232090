import { Grid } from '@mui/material'
import MainSection from '../../components/locations/list/MainSection'
import TopSection from '../../components/locations/list/TopSection'
import useGaPageView from '../../hooks/google/useGaPageView'

export default function LocationList() {
  useGaPageView('Locations list')
  
  return (
    <Grid width='100%' paddingX='.5rem'>
      <TopSection />
      <MainSection />
    </Grid>
  )
}