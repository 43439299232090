import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import NotFoundImage from '../../assets/images/404.png'
import useGaPageView from '../../hooks/google/useGaPageView'
import ErrorPage from './ErrorPage'

export default function NotFoundError() {
  useGaPageView('404')
  const texts = useTranslation('errors').t
  const navigate = useNavigate()

  return (
    <ErrorPage
      image={NotFoundImage}
      title={texts('page_not_found_title')}
      description={texts('page_not_found_subtitle')}
      button={{
        label: texts('page_not_found_button_label'),
        onClick: () => navigate('/', { replace: true })
      }}
      sx={{
        img: {
          marginBottom: '2rem',
          width: '24.625rem',
          height: '20rem'
        }
      }}
    />
  )
}
