import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { TrainerListOptionsDto } from '../../../api/data/types'
import { mapTrainerListDto } from '../../../mapping/trainers'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled: boolean
}

export default function useGetTrainerList(options: TrainerListOptionsDto & Options) {
  const texts = useTranslation().t
  const key = [
    queryNames.getTrainerList,
    options.order,
    options.search,
    options.limit,
    options.offset,
    options.from,
    options.to
  ]
  const query = useQuery(key, async () => {
    const { data } = await dataApi.getTrainerList(options)
    return mapTrainerListDto(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}
