import { Box, Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useGaPageView from '../../hooks/google/useGaPageView'
import { InternetErrorBase64Src } from '../../utils/const'

type Props = {
  onReload: () => void
}

export default function ListConnectionError(props: Props) {
  useGaPageView('Refresh error')
  const texts = useTranslation('errors').t

  return (
    <Grid
      width='100%'
      container
      direction='column'
      alignItems='center'
    >
      <Box
        alignSelf='center'
        component='img'
        src={InternetErrorBase64Src}
        height='12.5rem'
        width='18.125rem'
        sx={{
          marginBottom: '2rem'
        }}
      />
      <Typography variant='h3' sx={{ textAlign: 'center', marginBottom: '1.5rem' }}>
        {texts('connection_error_title')}
      </Typography>
      <Typography variant='body1' sx={{ textAlign: 'center',  marginBottom: '2rem' }}>
        {texts('connection_error_subtitle')}
      </Typography>
      <Button
        disableElevation
        variant='contained'
        onClick={props.onReload}
        sx={{
          width: '17.5rem',
        }}
      >
        {texts('connection_error_button_label')}
      </Button>
    </Grid>
  )
}