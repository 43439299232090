import { Button, Grid, Menu, MenuItem, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLogout } from '../../hooks/auth/useLogout'
import DownArrowIcon from '../icons/DownArrowIcon'
import LogoutIcon from '../icons/LogoutIcon'
import UpArrowIcon from '../icons/UpArrowIcon'
import UserIcon from '../icons/UserIcon'

type Props = {
  userName: string
}

export default function AccountNav(props: Props) {
  const texts = useTranslation('common').t
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const mutation = useLogout()

  return (
    <Grid item>
      <Button
        variant='outlined'
        disableRipple
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          width='100%'
          gap='0.46875rem'
          flexWrap='nowrap'
          maxWidth='100%'
        >
          <UserIcon 
            sx={{
              flexShrink: 0,
              width: '1rem'
            }}
          />
          <Grid
            item
            container
            flexGrow={1}
            maxWidth='calc(100% - 2.75rem)'
          >
            <Typography 
              variant='button'
              color='#000'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                wordBreak: 'keep-all',
                whiteSpace: 'nowrap'
              }}
            >
              {props.userName}
            </Typography>
          </Grid>
          {
            Boolean(anchorEl)
            ? <UpArrowIcon
                sx={{
                  flexShrink: 0,
                  width: '0.75rem',
                  height: '0.53125rem'
                }}
              />
            : <DownArrowIcon
                sx={{
                  flexShrink: 0,
                  width: '0.75rem',
                  height: '0.53125rem'
                }}
              />
          }
        </Grid>
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '18rem'
          },
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {/* TODO uncomment when settings page has been added */}
        {/* <MenuItem
          disableRipple
          onClick={() => {
            //TODO navigate to account settings
          }}
        >
          <SettingsIcon sx={{ width: '1.125rem' }}/>
          <Typography variant='menuItem'>
            {texts('settings')}
          </Typography>
        </MenuItem>
        <Divider 
          sx={{
            width: 'calc(100% - 2rem)',
            borderWidth: '0.0625rem',
            color: '#BCBCBC',
            '&.MuiDivider-root': {
              margin: '0 0 0 1rem'
            }
          }}
        /> */}
        <MenuItem
          disableRipple
          onClick={() => mutation.mutate()}
        >
          <LogoutIcon sx={{ width: '1.125rem' }}/>
          <Typography variant='menuItem'>
            {texts('logout')}
          </Typography>
        </MenuItem>
      </Menu>
    </Grid>
  )
}