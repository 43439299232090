import { CircularProgress, Grid } from '@mui/material'

type Props = {
  width?: string
  height?: string
  size?: number
}

export default function Loader(props: Props) {
  return (
    <Grid
      container
      width={props.width ?? '100%'}
      height={props.height ?? '100%'}
      alignItems='center'
      justifyContent='center'
    >
      <CircularProgress size={props.size ?? 80 } thickness={1.5}/>
    </Grid>
  )
}

