import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { EditTrainingRoomDto, EquipmentDto } from '../../../api/data/types'
import { mapTrainingRoomDto } from '../../../mapping/rooms'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import { HttpError } from '../../../types/common'
import { Room } from '../../../types/rooms'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  locationUuid: string
  data: EditTrainingRoomDto
}

export default function useEditRoom(uuid: string) {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation(async (params: MutationParams) => {
    const { data } = await dataApi.editTrainingRoom(uuid, params.data)
    return mapTrainingRoomDto(data.data!)
  }, {
    onError: (error: AxiosError<HttpError>) => {
      if (isDataError(error, DataSubcodes.EQUIPMENT_ITEM_NOT_FOUND)) {
        const queryData = queryClient.getQueryData<EquipmentDto[]>([queryNames.getEquipmentList])
        const newData = queryData?.filter(item => !error.response!.data.data!.uuids.includes(item.uuid))
        queryClient.setQueryData([queryNames.getEquipmentList], newData)
      } else if (isDataError(error, [DataSubcodes.PLACE_NOT_FOUND, DataSubcodes.PLACE_REMOVED])) {
        queryClient.resetQueries([queryNames.getPlaceList])
      } else  if (isDataError(error, [DataSubcodes.TRAINING_ROOM_NOT_FOUND, DataSubcodes.TRAINING_ROOM_REMOVED])) {
        queryClient.resetQueries([queryNames.getRoomList])
        queryClient.removeQueries([queryNames.getRoomDetails, uuid])
      }
      showErrorToast(error, texts)
    },
    onSuccess: (data: Room, variables: MutationParams) => {
      queryClient.setQueryData([queryNames.getRoomDetails, uuid], data)
      queryClient.resetQueries([queryNames.getPlaceList])
      queryClient.resetQueries([queryNames.getRoomList, variables.locationUuid])
      toast.success(texts('successes:room_updated'))
    }
  })

  return mutation
}
