import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import moment from 'moment-timezone'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import useCreateEventPattern from '../../../hooks/data/eventPatterns/useCreateEventPattern'
import useEditEventPattern from '../../../hooks/data/eventPatterns/useEditEventPattern'
import useSearchLocations from '../../../hooks/data/locations/useSearchLocations'
import { mapEventPatternFormData } from '../../../mapping/eventPatterns'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import { PatternPageReferrer, patternRepeatSelectOptions, PatternRepeatType, weekDaysToggleButtonOptions } from '../../../types/common'
import { EventPatternFormData } from '../../../types/forms/data'
import { LocationSearchOption, TrainingRoomOption } from '../../../types/locations'
import { eventPatternFormDataValidation } from '../../../validations/eventPattern'
import FormikDatePicker from '../../customMui/FormikDatePicker'
import FormikExternalAutocomplete from '../../customMui/FormikExternalAutocomplete'
import FormikMultiAutocomplete from '../../customMui/FormikMultiAutocomplete'
import FormikSelectField from '../../customMui/FormikSelectField'
import FormikTimePicker from '../../customMui/FormikTimePicker'
import FormikToggleButtonGroup from '../../customMui/FormikToggleButtonGroup'
import TextField from '../../customMui/TextField'
import { mapSelectedLocationList } from '../../../mapping/common'
import { queryNames } from '../../../hooks/queries'
import { useQueryClient } from '@tanstack/react-query'

type Props = {
  isEditPage: boolean
  initialValues?: EventPatternFormData
}

const defaultInitialValues: EventPatternFormData = {
  location: null,
  rooms: [],
  startTime: null,
  endTime: null,
  duration: parseInt(process.env.REACT_APP_EVENT_PATTERN_DURATION_IN_MIN ?? '60'),
  startDate: moment().format('DD.MM.YYYY'),
  endDate: moment()
    .add(process.env.REACT_APP_EVENT_PATTERN_MAX_DURATION_IN_YEARS!, 'y')
    .subtract(1, 'd')
    .format('DD.MM.YYYY'),
  repeatType: PatternRepeatType.NO_REPEAT,
  weekDays: []
}

export default function MainSection(props: Props) {
  const texts = useTranslation('eventPatternPage').t
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const queryClient = useQueryClient()

  const { uuid } = useParams<{ uuid?: string }>()

  const createMutation = useCreateEventPattern()
  const editMutation = useEditEventPattern(uuid ?? '')

  const [locationSearch, setLocationSearch] = useState<string>('')

  const locationList = useSearchLocations(locationSearch)

  const debounceLocationSearch = useDebouncedCallback((value) => {
    setLocationSearch(value)
    queryClient.resetQueries([queryNames.searchLocations, value])
  }, 300)

  const handleSubmit = (data: EventPatternFormData, formikHelpers: FormikHelpers<EventPatternFormData>) => {
    if (props.isEditPage) {
      editMutation.mutate({ data: mapEventPatternFormData(data) }, {
        onSuccess: () => {
          formikHelpers.resetForm({ values: data })
        },
        onError: (error) => {
          if (isDataError(error, [DataSubcodes.EVENT_PATTERN_NOT_FOUND, DataSubcodes.EVENT_PATTERN_REMOVED])) {
            navigate(-1)
          }
        }
      })
    } else {
      createMutation.mutate({
        data: mapEventPatternFormData(data)
      }, {
        onSuccess: () => {
          navigate(location.state?.referrer === PatternPageReferrer.PATTERN_LIST ? '/schedule/event-patterns' : '/schedule', { replace: true })
        },
      })
    }
  }

  return (
    <Grid
      container
      justifyContent='center'
    >
      <Card 
        sx={{ 
          width: '80%',
          '.MuiCardContent-root': {
            padding: '2rem',
            ':last-child': {
              padding: '2rem'
            }
          }
        }}
      >
        <CardContent>
          <Formik<EventPatternFormData> 
            initialValues={props.initialValues ?? defaultInitialValues}
            onSubmit={handleSubmit}
            validationSchema={eventPatternFormDataValidation(texts)}
          >
            {(formikProps) => (
              <Form>
                <FormikExternalAutocomplete<LocationSearchOption>
                  name='location'
                  disabled={props.isEditPage}
                  options={mapSelectedLocationList(locationList.data ?? [])}
                  label={texts('location_field_label')}
                  loading={locationList.isFetching}
                  onInputChange={(event, value) => {
                    if (event) {
                      debounceLocationSearch(event.type === 'change' ? value : '')
                    }
                  }}
                  getOptionLabel={(option) => texts('inactive_option_label', { active: option.active ? 1 : 0, label: option.label })}
                  onChange={() => { formikProps.setFieldValue('rooms', []) }} 
                />
                <FormikMultiAutocomplete<TrainingRoomOption>
                  name='rooms'
                  label={texts('rooms_field_label')}
                  disabled={!formikProps.values.location}
                  options={formikProps.values.location?.trainingRooms ?? []}
                  getOptionLabel={(option) => texts('inactive_option_label', { active: option.active ? 1 : 0, label: option.label })}

                />
                <Typography
                  variant='body1'
                  fontWeight={500}
                  marginBottom='1rem'
                >
                  {texts('hours_section_title')}
                </Typography>
                <Grid 
                  container
                  columnGap='.5rem'
                >
                  <Grid item xs={3}>
                    <FormikTimePicker
                      name='startTime'
                      label={texts('start_time_field_label')}
                      shrink
                      onChange={(time) => {
                        formikProps.setFieldValue(
                          'endTime',
                          time != null ? moment(time).add(formikProps.values.duration, 'minutes').format('HH:mm') : null,
                          false
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormikTimePicker
                      disabled
                      name='endTime'
                      label={texts('end_time_field_label')}
                      shrink
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      name='duration'
                      label={texts('duration_field_label')}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Typography
                  variant='body1'
                  fontWeight={500}
                  marginBottom='1rem'
                >
                  {texts('date_section_title')}
                </Typography>
                <Grid item xs={4}>
                  <FormikDatePicker
                    name='startDate'
                    label={texts('start_date_field_label')}
                    disabled={props.isEditPage}
                    minDate={
                      moment()
                        .tz(formikProps.values.location?.tz ?? process.env.REACT_APP_DEFAULT_TZ!)
                        .toDate()
                    }
                    maxDate={
                      moment()
                        .add(process.env.REACT_APP_EVENT_PATTERN_MAX_DURATION_IN_YEARS!, 'y')
                        .subtract(1, 'd')
                        .toDate()
                    }
                  />
                </Grid>
                <Typography
                  variant='body1'
                  fontWeight={500}
                  marginBottom='1rem'
                >
                  {texts('repeat_section_title')}
                </Typography>
                <Grid item xs={4}>
                  <FormikSelectField
                    name='repeatType'
                    disabled={props.isEditPage}
                    label={texts('repeat_type_field_label')}
                    options={
                      props.isEditPage && moment(formikProps.values.startDate, 'DD.MM.YYYY').tz(formikProps.values.location?.tz ?? process.env.REACT_APP_DEFAULT_TZ!).isBefore(moment().tz(formikProps.values.location?.tz ?? process.env.REACT_APP_DEFAULT_TZ!).startOf('day'))
                      ? patternRepeatSelectOptions.filter(e => e.value !== PatternRepeatType.NO_REPEAT)
                      : patternRepeatSelectOptions
                    }
                  />
                  {
                    formikProps.values.repeatType === PatternRepeatType.EVERY_WEEK
                    &&  <Grid marginBottom='2rem'>
                          <FormikToggleButtonGroup 
                            name='weekDays'
                            options={weekDaysToggleButtonOptions}
                          />
                        </Grid>
                  }
                  {
                    formikProps.values.repeatType !== PatternRepeatType.NO_REPEAT
                    &&  <FormikDatePicker
                          name='endDate'
                          label='Powtarzaj do'
                          minDate={
                            formikProps.values.startDate != null 
                            ? moment(formikProps.values.startDate, 'DD.MM.YYYY').add(1, 'd').toDate() 
                            : moment().tz(formikProps.values.location?.tz ?? process.env.REACT_APP_DEFAULT_TZ!).toDate()
                          }
                          maxDate={
                            moment()
                              .tz(formikProps.values.location?.tz ?? process.env.REACT_APP_DEFAULT_TZ!)
                              .add(process.env.REACT_APP_EVENT_PATTERN_MAX_DURATION_IN_YEARS!, 'y')
                              .subtract(1, 'd')
                              .toDate()
                          }
                        />
                  }
                </Grid>
                {
                  props.isEditPage
                  &&  <Grid
                        sx={{
                          borderRadius: '.5rem',
                          backgroundColor: 'RGBA(224, 32, 32, 0.1)',
                          padding: '1rem',
                          marginBottom: '1.5rem'
                        }}
                      >
                        <Typography
                          variant='body1'
                          fontWeight={700}
                          color={theme.palette.error.main}
                        >
                          {texts('edit_warning')}
                        </Typography>
                      </Grid>
                }
                <Grid
                  container
                  justifyContent='flex-end'
                >
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    disabled={!formikProps.dirty || !formikProps.isValid}
                    loading={createMutation.isLoading || editMutation.isLoading}
                    sx={{
                      width: '10rem'
                    }}
                  >
                    {texts(props.isEditPage ? 'save_button' : 'add_button')}
                  </LoadingButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Grid>
  )
}