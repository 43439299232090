import { Grid, Pagination } from '@mui/material'
import { ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DecodedValueMap, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import useGetPlaceList from '../../../hooks/data/locations/useGetPlaceList'
import EmptyListPlaceholder from '../../common/EmptyListPlaceholder'
import ListConnectionError from '../../common/ListConnectionError'
import Loader from '../../common/Loader'
import Tile from './Tile'
import { useQueryClient } from '@tanstack/react-query'
import { queryNames } from '../../../hooks/queries'
import { LocationListSearchParams } from '../../../types/locations'
import { contentContainerId } from '../../../utils/const'

const locationsPerPage = Number.parseInt(process.env.REACT_APP_LOCATIONS_PER_PAGE!)

function isSearchParamsValid(params: DecodedValueMap<LocationListSearchParams>): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
}

function Container(props: { children: ReactNode | ReactNode[] }) {
  return (
    <Grid 
      container 
      flexDirection='column'
      width='100%'
      marginY='1.5rem'
      gap='1.5rem'
    >
      {props.children}
    </Grid>
  )
}

export default function MainSection() {
  const texts = useTranslation('locationList').t
  const queryClient = useQueryClient()

  const [searchParams, setSearchParams] = useQueryParams<LocationListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    search: withDefault(StringParam, undefined)
  })

  const locationList = useGetPlaceList({
    search: searchParams.search ? decodeURI(searchParams.search) : undefined,
    limit: locationsPerPage,
    offset: (searchParams.page - 1) * locationsPerPage,
    enabled: isSearchParamsValid(searchParams),
    onSuccess: (data) => {
      if (searchParams.page > 1 && data.places.length === 0) {
        setSearchParams({
          page: Math.ceil((data.totalCount ?? 0) / locationsPerPage),
        }, 'replaceIn')
      }
    }
  })

  const maxPage = useMemo(() => {
    return Math.ceil((locationList.data?.totalCount ?? 0) / locationsPerPage)
  }, [locationList.data?.totalCount])

  useEffect(() => {
    document.getElementById(contentContainerId)?.scrollTo(0, 0)
  }, [searchParams.page])

  useEffect(() => {
    if (!isSearchParamsValid(searchParams)) {
      setSearchParams({
        page: 1, 
        search: ''
      }, 'replaceIn')
      return
    }
    
    if (searchParams.page === 1) {
      setSearchParams({
        page: 1
      }, 'replaceIn')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onPageChange = (_: React.ChangeEvent<any>, value: number) => {
    setSearchParams({ page: value, search: searchParams.search }, 'push')
  }

  if (locationList.isFetching) {
    return (
      <Container>
        <Grid marginTop='10rem' width='100%'>
          <Loader />
        </Grid>
      </Container>
    )
  }

  const isPageNotFound = searchParams.page > 1 && locationList.data!.places.length === 0
  if (!isSearchParamsValid(searchParams) || isPageNotFound) {
    return <></>
  }
  
  if (locationList.isError) {
    return (
      <Container>
        <Grid marginTop='10rem' width='100%'>
        <ListConnectionError 
                onReload={() => {
                  queryClient.resetQueries([
                    queryNames.getPlaceList,
                    searchParams.search,
                    locationsPerPage,
                    (searchParams.page - 1) * locationsPerPage
                  ])
                }}
              />
        </Grid>
      </Container>
    )
  }

  if (locationList.data!.places.length === 0) {
    return (
      <Container>
        <Grid marginTop='10rem' width='100%'>
          <EmptyListPlaceholder 
            title={texts('no_data_title')}
            description={texts('no_data_description')}
          />
        </Grid>
      </Container>
    )
  }

  return (
    <Container>
      <Grid
        item
        container
        width='100%'
        flexDirection='column'
        gap='.75rem'
      >
        {
          locationList.data!.places.map(place => 
            <Tile 
              key={place.uuid} 
              location={place} 
            />
          )
        }
      </Grid>
      <Pagination 
        count={maxPage}
        page={searchParams.page}
        color='secondary'
        onChange={onPageChange}
        sx={{
          alignSelf: 'center'
        }}
      />
    </Container>
  )
}