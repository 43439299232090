import { Autocomplete, TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { SelectOption } from '../../types/common'

type Props = {
  name: string
  label: string
  options: SelectOption[]
  placeholder?: string
  shrink?: boolean
}

export default function FormikMultiSelectField(props: Props) {
  const texts = useTranslation().t
  const [field, meta, helpers] = useField<(number | string)[]>(props.name)

  return (
    <Autocomplete<SelectOption, true, true, false> 
      {...field}
      {...props}
      value={props.options.filter(e => field.value.includes(e.value)) ?? undefined}
      onChange={(_, val: SelectOption[]) => {
        helpers.setValue(val.map(e => e.value))
      }}
      filterSelectedOptions
      noOptionsText={texts('common:no_options_placeholder')}
      fullWidth
      multiple
      renderInput={(params: TextFieldProps) => (
        <MuiTextField
          {...params}
          name={props.name}
          onBlur={field.onBlur}
          label={props.label}
          placeholder={field.value.length > 0 ? undefined : props.placeholder}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && !!meta.error ? meta.error : ' '}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...(props.shrink != null ? { shrink: props.shrink } : {})
          }}
        />
      )}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        } else {
          return option.i18 ? texts(option.i18!.text, option.i18!.params) as any : option.label ?? ''
        }
      }}
    />
  )
}