import { KeyboardBackspace } from '@mui/icons-material'
import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type Props = {
  isEditPage: boolean
}

export default function TopSection(props: Props) {
  const texts = useTranslation('exceptionPage').t
  const navigate = useNavigate()

  return (
    <Grid marginY='2rem'>
      <Button 
        variant='text'
        onClick={() => { navigate(-1) }}
        sx={{ marginBottom: '1rem' }}
      >
        <Grid
          container
          alignItems='center'
          gap='.5rem'
        >
          <KeyboardBackspace sx={{ marginTop: '-.125rem' }} />
          {texts('back_to_list')}
        </Grid>
      </Button>
      <Grid
        container
        justifyContent='space-between'
        marginBottom='.25rem'
      >
        <Typography variant='h2'>
          {texts(props.isEditPage ? 'edit_title' : 'create_title')}
        </Typography>
      </Grid>
    </Grid>
  )
}