import { Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { ToggleButtonOption } from '../../types/common'

type Props = {
  name: string
  options: ToggleButtonOption[]
}

export default function FormikToggleButtonGroup(props: Props) {
  const texts = useTranslation().t
  const [field, meta, helpers] = useField(props.name)

  return (
    <>
      <ToggleButtonGroup
        value={field.value}
        onChange={(_, value) => {
          helpers.setValue(value)
          helpers.setTouched(true, false)
        }}
      >
        {
          props.options.map((option, idx) => (
            <ToggleButton
              key={`toggle-button-${props.name}-${idx}`}
              value={option.value}
            >
              <Typography variant='button' textTransform='none'>
                {option.i18 ? texts(option.i18!.text, option.i18!.params) as string : (option.label ?? '')}
              </Typography>
            </ToggleButton>
          ))
        }
      </ToggleButtonGroup>
      {
        meta.touched && !!meta.error
        && <Grid marginTop='.5rem'>
            <Typography variant='error'>
              {meta.error}
            </Typography>
          </Grid>
      }
    </>
  )
}