import { AccountType, SelectOption, Uuid } from './common'
import { QueryParamConfig } from 'use-query-params'

export type ScheduleTableDate = {
  date: string
  label: string
  dayOfWeek: number
}

export type ScheduleTableEntry = {
  hour: string
  data: {
    date: string
    data: Event[]
  }[]
}

export type Event = {
  uuid: string
  takenSeats: number
  totalSeats: number
  start: string
  end: string
  isPast: boolean
  place: Location
  trainingRoom: TrainingRoom
  pattern: Uuid
}

export type Location = {
  uuid: string
  name: string
  tz: string
  active: boolean
}

export type TrainingRoom = {
  uuid: string
  name: string
  color: string
  active: boolean
}

export enum TimeOfDay {
  MORNING = 0,    // 05:00-11:00
  MIDDAY = 1,     // 11:00-17:00
  EVENING = 2,    // 17:00-23:00
  NIGHT = 3       // 23:00-05:00
}

export const timeOfDaySelectOptions: SelectOption[] = [
  {
    value: TimeOfDay.MORNING,
    i18: {
      text: 'schedule:time_of_day_label',
      params: {
        value: TimeOfDay.MORNING
      }
    }
  },
  {
    value: TimeOfDay.MIDDAY,
    i18: {
      text: 'schedule:time_of_day_label',
      params: {
        value: TimeOfDay.MIDDAY
      }
    }
  },
  {
    value: TimeOfDay.EVENING,
    i18: {
      text: 'schedule:time_of_day_label',
      params: {
        value: TimeOfDay.EVENING
      }
    }
  },
  {
    value: TimeOfDay.NIGHT,
    i18: {
      text: 'schedule:time_of_day_label',
      params: {
        value: TimeOfDay.NIGHT
      }
    }
  }
]

export type ScheduleSearchParams = {
  from: QueryParamConfig<string | null | undefined, string>
  to: QueryParamConfig<string | null | undefined, string>
  places: QueryParamConfig<string | null | undefined, string | undefined>
  rooms: QueryParamConfig<string | null | undefined, string | undefined>
  timesofday: QueryParamConfig<string | null | undefined, string | undefined>
  locationState: QueryParamConfig<number | null | undefined, number>
}

export type ReservationOwner = {
  uuid: string
  firstName: string
  lastName: string | null
  contactEmail: string
  phone: string
  type: AccountType
}