import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { EventDto } from '../../../api/data/types'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
}

export default function useRemoveEvent() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation((data: MutationParams) => dataApi.removeEvent(data.uuid), {
    onError: (error: AxiosError<HttpError>, variables: MutationParams) => {
      if (isDataError(error, DataSubcodes.EVENT_NOT_FOUND)) {
        const queriesData = queryClient.getQueriesData<EventDto[]>([queryNames.getEventList])
        for (const queryData of queriesData) {
          queryClient.setQueryData(queryData[0], (oldData?: EventDto[]) => {
            if (!oldData) {
              return oldData
            }
            const newData = [...oldData]
            newData.splice(newData.findIndex(p => p.uuid === variables.uuid), 1)
            return newData
          })
        }
      }
      showErrorToast(error, texts)
    },
    onSuccess: (_, variables: MutationParams) => {
      const queriesData = queryClient.getQueriesData<EventDto[]>([queryNames.getEventList])
      for (const queryData of queriesData) {
        queryClient.setQueryData(queryData[0], (oldData?: EventDto[]) => {
          if (!oldData) {
            return oldData
          }
          const newData = [...oldData]
          newData.splice(newData.findIndex(p => p.uuid === variables.uuid), 1)
          return newData
        })
      }
      toast.success(texts('successes:event_removed'))
    }
  })

  return mutation
}
