import { TFunction, Namespace } from 'i18next'
import * as Yup from 'yup'
import { PatternRepeatType } from '../types/common'
import moment from 'moment'

export function eventPatternFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    location: Yup.object()
      .nullable()
      .required(t('validations:field_required')),
    rooms: Yup.array()
      .min(1, t('validations:field_required')),
    startTime: Yup.string()
      .nullable()
      .required(t('validations:field_required')),
    startDate: Yup.string()
      .nullable()
      .required(t('validations:field_required')),
    repeatType: Yup.number()
      .required(t('validations:field_required')),
    weekDays: Yup.array()
      .when('repeatType', {
        is: (repeatType: PatternRepeatType) => repeatType === PatternRepeatType.EVERY_WEEK,
        then: (schema) => schema.min(1, t('validations:field_required'))
      }),
    endDate: Yup.string()
      .nullable()
      .when('repeatType', {
        is: (repeatType: PatternRepeatType) => repeatType !== PatternRepeatType.NO_REPEAT,
        then: (schema) => schema
          .nullable()
          .required(t('validations:field_required'))
      })
      .test('end_date_after_start_date', t('validations:end_date_before_start_date'), function (val, ctx) {
        const repeatType = this.resolve(Yup.ref<PatternRepeatType>('repeatType'))
        const startDate = this.resolve(Yup.ref<string | null>('startDate'))
        return startDate == null || repeatType === PatternRepeatType.NO_REPEAT || val == null || moment(startDate, 'DD.MM.YYYY').isBefore(moment(val, 'DD.MM.YYYY'))
      })
  })
}