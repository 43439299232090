import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthDto } from '../api/auth/types'
import { setUserData, clearUserCache, getUserUuid, setUserName, getUserName } from '../services/storage/storageService'

export enum LoginState {
  LOGGED,
  LOGGED_OUT,
  FORCED_LOGGED_OUT
}

const defaultState = {
  loginState: getUserUuid() != null ? LoginState.LOGGED : LoginState.LOGGED_OUT,
  userName: getUserName() ?? ''
}

const slice = createSlice({
  name: 'storage',
  initialState: defaultState,
  reducers: {
    logIn: (state, action: PayloadAction<AuthDto>) => {
      setUserData(action.payload)
      state.loginState = LoginState.LOGGED
      state.userName = action.payload.name
    },
    logOut: state => {
      clearUserCache()
      state.loginState = LoginState.LOGGED_OUT
    },
    forceLogOut: state => {
      clearUserCache()
      state.loginState = LoginState.FORCED_LOGGED_OUT
    },
    updateUserName: (state, action: PayloadAction<string>) => {
      setUserName(action.payload)
      state.userName = action.payload
    }
  }
})

export const {
  logIn,
  logOut,
  forceLogOut,
  updateUserName
} = slice.actions

export default slice.reducer