import { Event } from '@mui/icons-material'
import { Grid, InputAdornment, TextField, Typography, useTheme } from '@mui/material'
import { DateRange, DateRangePicker as MuiDateRangePicker } from '@mui/x-date-pickers-pro'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

type Props = {
  value: DateRange<Date>
  labels: {
    from: string
    middle: string
    to: string
  }
  maxDate: Date
  onChange: (dates: DateRange<Date>) => void
}

export default function DateRangePicker(props: Props) {
  const texts = useTranslation('common').t
  const theme = useTheme()

  return (
    <MuiDateRangePicker
      value={props.value}
      maxDate={props.maxDate}
      onChange={(dates) => {
        props.onChange(dates)
      }}
      renderInput={(startProps, endProps) => (
        <Grid
          container
          flexWrap='nowrap'
          gap='.5rem'
          alignItems='center'
        >
          <TextField
            {...startProps}
            label={props.labels.from}
            inputProps={{
              ...startProps.inputProps,
              placeholder: texts('date_placeholder')
            }}
            InputProps={
              {
                ...startProps.InputProps,
                endAdornment: 
                  <InputAdornment position='end'>
                    <Event sx={{ color: theme.palette.primary.main }}/>
                  </InputAdornment>
              }
            }
            error={props.value[0] == null || !moment(props.value[0]).isValid()}
          />
          <Typography 
            variant='body1' 
            sx={{ marginTop: '-.375rem' }}
          >
            {props.labels.middle}
          </Typography>
          <TextField
            {...endProps}
            label={props.labels.to}
            inputProps={{
              ...endProps.inputProps,
              placeholder: texts('date_placeholder')
            }}
            error={props.value[1] == null || !moment(props.value[1]).isValid()}
            InputProps={
              {
                ...endProps.InputProps,
                endAdornment: 
                  <InputAdornment position='end'>
                    <Event sx={{ color: theme.palette.primary.main }}/>
                  </InputAdornment>
              }
            }
          />
        </Grid>
      )}
    />
  )
}
