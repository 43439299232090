import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { EventPatternListOptionsDto } from '../../../api/data/types'
import { mapEventPatternListDto } from '../../../mapping/eventPatterns'
import { HttpError } from '../../../types/common'
import { EventPatternList } from '../../../types/eventPatterns'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled: boolean
  onSuccess: (data: EventPatternList) => void
}

export default function useGetEventPatternList(options: EventPatternListOptionsDto & Options) {
  const texts = useTranslation().t

  const query = useQuery([queryNames.getEventPatternList, options.limit, options.offset, options.timezone], async () => {
    const { data } = await dataApi.getEventPatternList(options)
    return mapEventPatternListDto(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onSuccess: options.onSuccess,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}
