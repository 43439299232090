import { TFunction } from 'i18next'
import { Codes, InternalSubcodes } from '../services/errors/consts'
import toast from 'react-hot-toast'
import { mapHttpErrorMessage } from '../services/errors/mapping'
import { AxiosError } from 'axios'
import { HttpError } from '../types/common'

export function showErrorToast(error: AxiosError<HttpError>, translations: TFunction) {
  const isForcedLogout = error.response?.data.code === Codes.INTERNAL && error.response?.data.subcode === InternalSubcodes.FORCED_LOGOUT
  if (!isForcedLogout) {
    toast.error(translations(mapHttpErrorMessage(error)))
  }
}
