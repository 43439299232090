import { LoginDto, EmailDto } from '../api/auth/types'
import { EmailFormData, LoginFormData } from '../types/forms/auth'

export function mapLoginFormData(form: LoginFormData): LoginDto {
  return {
    ...form
  }
}

export function mapEmailFormData(form: EmailFormData): EmailDto {
  return {
    ...form
  }
}