import { AxiosError } from 'axios'
import { TFuncKey, Namespace } from 'i18next'
import { HttpError } from '../../types/common'
import { AuthSubcodes, Codes, DataSubcodes } from './consts'

export function mapHttpErrorMessage(e: AxiosError<HttpError>): TFuncKey<Namespace> {
  if (e.response?.data?.code === Codes.AUTH) {
    return handleAuthErrors(e)
  } else if (e.response?.data?.code === Codes.DATA) {
    return handleDataErrors(e)
  }

  return 'errors:something_went_wrong'
}

function handleAuthErrors(e: AxiosError<HttpError>): TFuncKey<Namespace> {
  let errorMessage: TFuncKey<Namespace> = 'errors:something_went_wrong'

  switch (e.response?.data!.subcode) {
    case AuthSubcodes.TOKEN_ALREADY_USED:
      errorMessage = 'errors:token_already_used'
      break
    case AuthSubcodes.USER_INACTIVE:
      errorMessage = 'errors:user_inactive'
      break
    case AuthSubcodes.UNAUTHORIZED:
      errorMessage = 'errors:unauthorized'
      break
    case AuthSubcodes.ACCOUNT_REMOVED:
      errorMessage = 'errors:account_removed'
      break
    case AuthSubcodes.INCORRECT_PWD:
      errorMessage = 'errors:wrong_current_password'
      break
    case AuthSubcodes.SAME_PWD_AS_PREVIOUS_ONE:
      errorMessage = 'errors:same_password_as_previous_one'
      break
    case AuthSubcodes.INVALID_CREDENTIALS:
      errorMessage = 'errors:invalid_credentials'
      break
    case AuthSubcodes.INVALID_TOKEN:
      errorMessage = 'errors:wrong_token'
      break
    case AuthSubcodes.INVALID_TOKEN_OR_USED:
      errorMessage = 'errors:invalid_token_or_used'
      break
    case AuthSubcodes.FORBIDDEN:
      errorMessage = 'errors:forbidden'
      break
    case DataSubcodes.EXCEPTION_NOT_FOUND:
      errorMessage = 'errors:exception_not_found'
      break
  }

  return errorMessage
}

function handleDataErrors(e: AxiosError<HttpError>): TFuncKey<Namespace> {
  let errorMessage: TFuncKey<Namespace> = 'errors:something_went_wrong'

  switch (e.response?.data!.subcode) {
    case DataSubcodes.BAD_REQUEST:
      errorMessage = 'errors:something_went_wrong'
      break
    case DataSubcodes.PLACE_NOT_FOUND:
      errorMessage = 'errors:place_not_found'
      break
    case DataSubcodes.PLACE_REMOVED:
      errorMessage = 'errors:place_removed'
      break
    case DataSubcodes.PHOTO_NOT_FOUND:
      errorMessage = 'errors:photo_not_found'
      break
    case DataSubcodes.PLACE_NAME_TAKEN:
      errorMessage = 'errors:place_name_taken'
      break
    case DataSubcodes.TRAINING_ROOM_NOT_FOUND:
      errorMessage = 'errors:training_room_not_found'
      break
    case DataSubcodes.TRAINING_ROOM_REMOVED:
      errorMessage = 'errors:training_room_removed'
      break
    case DataSubcodes.TRAINING_ROOM_NAME_TAKEN:
      errorMessage = 'errors:training_room_name_taken'
      break
    case DataSubcodes.EQUIPMENT_ITEM_NOT_FOUND:
      errorMessage = 'errors:equipment_item_not_found'
      break
    case DataSubcodes.ACCOUNT_NOT_FOUND:
      errorMessage = 'errors:data_account_not_found'
      break
    case DataSubcodes.ACCOUNT_REMOVED:
      errorMessage = 'errors:data_account_removed'
      break
    case DataSubcodes.EMAIL_TAKEN:
      errorMessage = 'errors:email_taken'
      break
    case DataSubcodes.TRAINING_ROOMS_NOT_FOUND:
      errorMessage = 'errors:training_rooms_not_found'
      break
    case DataSubcodes.TRAINING_ROOMS_REMOVED:
      errorMessage = 'errors:training_rooms_removed'
      break
    case DataSubcodes.EVENT_PATTERN_NOT_FOUND:
      errorMessage = 'errors:event_pattern_not_found'
      break
    case DataSubcodes.EVENT_PATTERN_REMOVED:
      errorMessage = 'errors:event_pattern_removed'
      break
    case DataSubcodes.INCORRECT_DATES:
      errorMessage = 'errors:incorrect_dates'
      break
    case DataSubcodes.INCORRECT_TIME:
      errorMessage = 'errors:incorrect_time'
      break
    case DataSubcodes.PATTERN_LOCATION_MISMATCH:
      errorMessage = 'errors:pattern_location_mismatch'
      break
    case DataSubcodes.EXCEPTION_LOCATION_MISMATCH:
      errorMessage = 'errors:exception_location_mismatch'
      break
    case DataSubcodes.EXCEPTION_NOT_FOUND:
      errorMessage = 'errors:exception_not_found'
      break
    case DataSubcodes.EVENT_NOT_FOUND:
      errorMessage = 'errors:event_not_found'
    break
    case DataSubcodes.REQUESTED_TRAINER_NOT_FOUND:
      errorMessage = 'errors:requested_trainer_not_found'
      break
    case DataSubcodes.REQUESTED_TRAINER_INACTIVE:
      errorMessage = 'errors:requested_trainer_inactive'
      break
    case DataSubcodes.LOCK_ALREADY_USED:
      errorMessage = 'errors:lock_already_used'
      break
    case DataSubcodes.NOT_ENOUGH_SEATS:
      errorMessage = 'errors:not_enough_seats'
      break
  }

  return errorMessage
}