import { LoadingButton } from '@mui/lab'
import { Box, Divider, Typography } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import Link from '../../components/customMui/Link'
import PasswordField from '../../components/customMui/PasswordField'
import TextField from '../../components/customMui/TextField'
import { useLogin } from '../../hooks/auth/useLogin'
import { LoginFormData } from '../../types/forms/auth'
import { mapLoginFormData } from '../../mapping/auth'
import { loginValidation } from '../../validations/auth'
import useGaPageView from '../../hooks/google/useGaPageView'


export default function Login() {
  useGaPageView('Sign in')
  const texts = useTranslation(['common', 'login']).t
  const mutation = useLogin()

  const handleSubmit = (data: LoginFormData, formikHelpers: FormikHelpers<LoginFormData>) => {
    mutation.mutate(mapLoginFormData(data), {
      onError: () => {
        formikHelpers.setFieldValue('password', '')
      },
    })
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      marginTop='5rem'
      width='100%'
    >
      <Typography variant='h1'>
        {texts('common:auth_pages_title')}
      </Typography>
      <Typography 
        variant='subtitle1'
        marginTop='1rem'
      >
        {texts('login:subtitle')}
      </Typography>
      <Formik<LoginFormData>
        initialValues={{ 
          email: '',
          password: ''
        }}
        onSubmit={handleSubmit}
        validationSchema={loginValidation(texts)}
      >
        {(formikProps) => (
          <Form>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              width='24.75rem'
              sx={{
                marginTop: '2.375rem',
              }}
            >
              <TextField
                name='email'
                label={texts('login:email_label')}
              />
              <PasswordField
                name='password'
                label={texts('login:password_label')}
              />
              <Box 
                width='100%'
                display='flex'
                justifyContent='flex-end'
              >
                <Link to='/password/reset/request'>
                  {texts('login:forgot_password')}
                </Link>
              </Box>
              <LoadingButton
                type='submit'
                variant='contained'
                loading={mutation.isLoading}
                disabled={!formikProps.dirty || !formikProps.isValid}
                sx={{
                  marginTop: '1.5rem',
                  width: '100%'
                }}
              >
                {texts('login:login')}
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
      <Divider 
        sx={{
          marginTop: '2.5rem', 
          width: '15rem'
        }}
      />
      <Typography 
        variant='body1'
        marginTop='1rem'
      >
        {texts('login:resend_link_description')}
        <Link to='/account/activation/resend'>
          {texts('login:resend_activation')}
        </Link>
      </Typography>
    </Box>
  )
}