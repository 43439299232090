import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FieldArray, Form, Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import useCreateRoom from '../../../hooks/data/rooms/useCreateRoom'
import useEditRoom from '../../../hooks/data/rooms/useEditRoom'
import { mapRoomFormDataForCreate, mapRoomFormDataForEdit } from '../../../mapping/rooms'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import { HttpError } from '../../../types/common'
import { RoomFormData } from '../../../types/forms/data'
import { Equipment } from '../../../types/rooms'
import { roomFormDataValidation } from '../../../validations/room'
import CheckboxTile from '../../checkboxTile/CheckboxTile'
import CustomColorPicker from '../../customColorPicker/CustomColorPicker'
import NumberField from '../../customMui/NumberField'
import TextField from '../../customMui/TextField'
import PhotoSection from './PhotoSection'

const defaultInitialValues: RoomFormData = {
  name: '',
  seats: 1,
  color: '#BBBBBB',
  equipments: [],
  photo: null
}

type Props = {
  isEditPage: boolean
  equipments: Equipment[]
  initialValues?: RoomFormData
}

export default function MainSection(props: Props) {
  const texts = useTranslation('roomPage').t
  const navigate = useNavigate()

  const { uuid, roomUuid } = useParams<{ uuid?: string; roomUuid?: string }>()

  const createMutation = useCreateRoom()
  const editMutation = useEditRoom(roomUuid ?? '')

  const handleSubmit = (data: RoomFormData, formikHelpers: FormikHelpers<RoomFormData>) => {
    if (props.isEditPage) {
      editMutation.mutate({ 
        locationUuid: uuid!,
        data: mapRoomFormDataForEdit(data) 
      }, {
        onError: (error: AxiosError<HttpError>) => {
          if (isDataError(error, DataSubcodes.EQUIPMENT_ITEM_NOT_FOUND)) {
            const newEquipments = data.equipments.filter(item => !error.response!.data.data!.uuids.includes(item))
            formikHelpers.setFieldValue('equipments', newEquipments)
          } else if (isDataError(error, [DataSubcodes.PLACE_NOT_FOUND, DataSubcodes.PLACE_REMOVED])) {
            navigate('/locations', { replace: true })
          } else if (isDataError(error, [DataSubcodes.TRAINING_ROOM_NOT_FOUND, DataSubcodes.TRAINING_ROOM_REMOVED])) {
            navigate(`/locations/${uuid}/rooms`, { replace: true })
          }
        }
      })
    } else {
      createMutation.mutate({ 
        data: mapRoomFormDataForCreate(data, uuid!) 
      }, {
        onSuccess: () => {
          navigate(`/locations/${uuid}/rooms`, { replace: true })
        },
        onError: (error: AxiosError<HttpError>) => {
          if (isDataError(error, DataSubcodes.EQUIPMENT_ITEM_NOT_FOUND)) {
            const newEquipments = data.equipments.filter(item => !error.response!.data.data!.uuids.includes(item))
            formikHelpers.setFieldValue('equipments', newEquipments)
          } else if (isDataError(error, [DataSubcodes.PLACE_NOT_FOUND, DataSubcodes.PLACE_REMOVED])) {
            navigate('/locations', { replace: true })
          }
        }
      })
    }
    
  }

  return (
    <Grid
      container
      justifyContent='center'
    >
      <Card 
        sx={{ 
          width: '80%',
          '.MuiCardContent-root': {
            padding: '2rem',
            ':last-child': {
              padding: '2rem'
            }
          }
        }}
      >
        <CardContent>
          <Formik<RoomFormData>
            initialValues={props.initialValues ?? {
              ...defaultInitialValues,
              equipments: props.equipments.map(item => item.uuid)
            }}
            onSubmit={handleSubmit}
            validationSchema={roomFormDataValidation(texts)}
          >
            {(formikProps) => (
              <Form>
                <Grid>
                  <Grid
                    display='grid'
                    gridTemplateColumns='20rem 1fr'
                    marginBottom='1.5rem'
                    columnGap='1.5rem'
                  >
                    <Grid
                      item
                      gridArea='1 / 1 / 2 / 2'
                      width='100%'
                    >
                      <PhotoSection />
                    </Grid>
                    <Grid
                      item
                      gridArea='1 / 2 / 2 / 3'
                      width='100%'
                    >
                      <Grid>
                        <TextField
                          name='name'
                          label={texts('name_label')}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <NumberField
                          name='seats'
                          label={texts('seat_count_label')}
                          min={1}
                          max={100}
                        />
                      </Grid>
                      <Grid marginBottom='1.5rem'>
                        <Typography
                          variant='body1'
                          fontWeight={500}
                          marginBottom='.5rem'
                        >
                          {texts('color_label')}
                        </Typography>
                        <CustomColorPicker name='color' />
                      </Grid>
                    </Grid>
                  </Grid>
                  
                  <Grid marginBottom='.5rem'>
                    <Typography
                      variant='body1'
                      fontWeight={500}
                      marginBottom='.5rem'
                    >
                      {texts('equipment_label')}
                    </Typography>
                    <FieldArray name='equipments'>
                      {({ remove, push }) => (
                        <Grid
                          display='grid' // CSS Grid so each tile can automatically scale height to same as biggest in row
                          gridTemplateColumns='1fr 1fr 1fr 1fr'
                          gap='.5rem'
                        >
                          {
                            props.equipments.map(item => (
                              <CheckboxTile
                                checked={formikProps.values.equipments.includes(item.uuid)}
                                label={item.name}
                                image={item.photo.thumbnail ?? item.photo.original}
                                onClick={(checked) => {
                                  const idx = formikProps.values.equipments.findIndex(entry => entry === item.uuid)
                                  if (idx !== -1) {
                                    remove(idx)
                                  } else {
                                    push(item.uuid)
                                  }
                                  formikProps.setFieldTouched('equipment', true)
                                }}
                              />
                            ))
                          }
                        </Grid>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent='flex-end'
                >
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    disabled={!formikProps.dirty || !formikProps.isValid}
                    loading={createMutation.isLoading}
                    sx={{
                      width: '10rem'
                    }}
                  >
                    {texts(props.isEditPage ? 'save_button' : 'add_button')}
                  </LoadingButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Grid>
  )
}