import { AuthDto, EmailDto, PasswordDto, LoginDto, TokenDto } from './types'
import { Api } from '../Api'
import { Success } from '../../types/common'
import { AxiosResponse } from 'axios'

export default class AuthApi extends Api {
  private constructor() {
    super({
      baseURL: `${process.env.REACT_APP_BASE_AUTH}/api/v1/admin/auth/account`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
  private static instance: AuthApi

  static getInstance(): AuthApi {
    if (AuthApi.instance) {
      return this.instance
    }
    return this.instance = new AuthApi()
  }

  public login(data: LoginDto): Promise<AxiosResponse<AuthDto>> {
    return this.post<AuthDto>('/login', data)
  }

  public logout(): Promise<AxiosResponse<Success>> {
    return this.post<Success>('/logout')
  }

  public resendActivationEmail(data: EmailDto): Promise<AxiosResponse<Success>> {
    return this.post<Success>('/email/resend', { email: data.email })
  }
  
  public checkActivationToken(data: TokenDto): Promise<AxiosResponse<AuthDto>> {
    return this.post<AuthDto>('/token/check', {}, {
      params: {
        token: data.token
      }
    })
  }

  public activateUser(data: PasswordDto & TokenDto): Promise<AxiosResponse<AuthDto>> {
    return this.patch<AuthDto>('/activate', { 
      password: data.password,
      token: data.token
    })
  }

  public requestPasswordReset(data: EmailDto): Promise<AxiosResponse<Success>> {
    return this.post<Success>('/password/reset/start', { email: data.email })
  }

  public checkResetPasswordToken(data: TokenDto): Promise<AxiosResponse<Success>> {
    return this.post<Success>('/password/reset/check', {}, {
      params: {
        token: data.token
      }
    })
  }

  public finishPasswordReset(data: PasswordDto & TokenDto): Promise<AxiosResponse<Success>> {
    return this.patch<Success>('/password/reset/finish', { 
      password: data.password,
      token: data.token
    })
  }
}