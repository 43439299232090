import { Box, Grid } from '@mui/material'
import PlaceholderImage from '../../../assets/images/locationImagePlaceholder.webp'
import { Location } from '../../../types/locations'

type Props = {
  location: Location
}

export default function TileImage(props: Props) {
  return (
    <Grid 
      item 
      width='10.625rem'
      height='6rem'
      flexShrink={0}
      sx={{
        opacity: props.location.active ? 1 : 0.5
      }}
    >
      <Box
        component='img'
        alt={`${props.location.name} photo`}
        flexShrink={0}
        src={props.location.photo?.thumbnail ?? props.location.photo?.original ?? PlaceholderImage}
        sx={{
          display: 'block',
          minWidth: '100%',
          minHeight: '100%',
          maxWidth: '100%',
          maxHeight: '100%'
        }}
      />
    </Grid>
  )
}