import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { PlaceBodyDto } from '../../../api/data/types'
import { mapPlaceDetailsDto } from '../../../mapping/locations'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  data: PlaceBodyDto
}

export default function useCreatePlace() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation(async (params: MutationParams) => {
    const { data } = await dataApi.createPlace(params.data)
    return mapPlaceDetailsDto(data.data!)
  }, {
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    },
    onSuccess: () => {
      queryClient.resetQueries([queryNames.getPlaceList])
      toast.success(texts('successes:location_created'))
    }
  })

  return mutation
}
