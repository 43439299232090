import { Card, CardContent, useTheme, Grid, Checkbox, Box, Typography } from '@mui/material'

type Props = {
  checked: boolean
  label: string
  image: string
  onClick: (checked: boolean) => void
}

export default function CheckboxTile(props: Props) {
  const theme = useTheme()
  return (
    <Card
      sx={{
        borderRadius: '.5rem',
        cursor: 'pointer',
        border: props.checked ? 'solid 1px black' : 'none',
        borderColor: theme.palette.secondary.main,
        position: 'relative',
        '.MuiCardContent-root': {
          padding: '1rem',
          ':last-child': {
            padding: '1rem'
          }
        }
      }}
      onClick={() => {
        props.onClick(!props.checked)
      }}
    >
      <Checkbox 
        checked={props.checked}
        sx={{
          container: {
            position: 'absolute',
            top: 0,
            left: 0
          }
        }}
      />
      <CardContent 
        sx={{ 
          padding: props.checked ? '.9375rem !important' : '1rem !important'
        }}
      >
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
          width='100%'
          height='100%'
          gap='.5rem'
        >
          <Box
            component='img'
            src={props.image}
            width='2.25rem'
            height='2.25rem'
          />
          <Typography
            variant='body2'
            lineHeight='1rem'
            textAlign='center'
          >
            {props.label}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  )
}